import React, { useContext, useState } from 'react'
import { EnvironmentContext } from '../context/EnvironmentContext'
import { ThemeContext } from '../context/ThemeContext'
import { FirebaseContext } from '../context/FirebaseContext'
import { UserContext } from '../context/UserContext'

import { Link } from "react-router-dom"
import { useHistory } from "react-router-dom";

import Screen from '../components/Screen'
import Colors from '../theme/Colors'
import Card from '../components/Card'

import WeedTypes from '../data/WeedTypes'
import SmokingMethods from '../data/SmokingMethods'
import DabSmokingMethods from '../data/DabSmokingMethods'
import EdibleMethods from '../data/EdibleMethods'
import WeedSizes from '../data/WeedSizes'
import EdibleSizes from '../data/EdibleSizes'
import SessionTimes from '../data/SessionTimes'
import Ratings from '../data/Ratings'
import NotesInput from '../components/NotesInput'
import DateTime from '../components/DateTime'



const titles = ['no use', 'Choose Your Type', 'Choose Your Method', 'Choose Your Size', 'Choose Your Time', 'Choose Your Rating', 'Add Session Notes']

const LogSession = props => {
    const [environment] = useContext(EnvironmentContext)
    // eslint-disable-next-line
    const [darkMode, setDarkMode] = useContext(ThemeContext);
    // eslint-disable-next-line
    const [user, setUser] = useContext(UserContext)
    const [firebase] = useContext(FirebaseContext)
    const history = useHistory();
    const [dataArray, setDataArray] = useState(WeedTypes)
    const [session, setSession] = useState({});
    const [step, setStep] = useState(1);
    const [path, setPath] = useState('')
    const [startDate, setStartDate] = useState(new Date());


    const handleClick = (id, name, value) => {
        if (step === 1) {
            setSession(pre => (
                {
                    ...pre,
                    weedTypeId: id,
                    weedTypeName: name
                }
            ))
            setPath(name)
            // Check and see which cannabis type was selected and set next array accordingly
            if (id === 1) setDataArray(SmokingMethods)
            if (id === 2) setDataArray(DabSmokingMethods)
            if (id === 3) setDataArray(EdibleMethods)
            setStep(2)
        } else if (step === 2) {
            setSession(pre => (
                {
                    ...pre,
                    methodId: id,
                    methodName: name
                }
            ))
            setStep(3)
            if (path !== "Edible") setDataArray(WeedSizes)
            if (path === "Edible") setDataArray(EdibleSizes)
        } else if (step === 3) {
            setSession(pre => (
                {
                    ...pre,
                    weedSizeId: id,
                    weedSizeName: name
                }
            ))
            setStep(4)
            setDataArray(SessionTimes)
        } else if (step === 4) {
            setSession(pre => (
                {
                    ...pre,
                    timeId: id,
                    timeName: name,
                    timeValue: value,
                    minutes: SessionTimes.filter(s => s.id === id)[0].minutes,
                    timeDisplayName: SessionTimes.filter(s => s.id === id)[0].displayName
                }
            ))
            setStep(5)
            setDataArray(Ratings)
        } else if (step === 5) {
            setSession(pre => (
                {
                    ...pre,
                    ratingId: id,
                    ratingName: name,
                    ratingValue: value
                }
            ))
            setStep(6)
        }

    }
    const handleNotes = (e) => {
        setSession(pre => (
            {
                ...pre,
                notes: e.target.value
            }))
    }
    const logSession = () => {
        // Submit to backend and push to home
        const date = startDate.getTime()
        console.log('date', date, startDate)
        const sessionFinal = {...session, date:date}
        firebase.database().ref(`${environment}/users/${user.uid}/sessions/${date}`).set(sessionFinal)
        history.push("/");
    }
    const styles = {
        icon: {
            color: darkMode ? Colors.white : Colors.darkGray
        },
        themeText: {
            color: darkMode ? Colors.white : Colors.darkGray
        },
        contentHolder: {
            width: '100%'
        },
        logBtn: {
            backgroundColor: Colors.green,
            color: Colors.white,
            width: '100%'
        }
    }

    return (
        <Screen align="is-align-items-center" justify="is-justify-content-center" nav={false}>
            <Link to="/" className="is-align-self-flex-start my-3">
                <i className="fas fa-times fa-2x" style={styles.icon}></i>
            </Link>
            <p className="has-text-centered my-3" style={styles.themeText}>{titles[step]}</p>
            <div className="is-flex is-justify-content-space-evenly is-flex-wrap-wrap" style={styles.contentHolder}>
                {step <= 5 ? dataArray.map(type => (
                    <Card
                        key={type.id}
                        img={type.img}
                        alt={type.alt}
                        name={type.name}
                        noImg={type.noImg}
                        imgSize={type.imgSize}
                        val={type.value}
                        onClick={() => handleClick(type.id, type.name, type.value)}
                    />)
                ) : <div className="w-100"><DateTime startDate={startDate} setStartDate={setStartDate}/><NotesInput onChange={handleNotes} value={session.notes} /></div>}
                {step <= 5 ? null : <button onClick={logSession} className="button my-4 is-medium" style={styles.logBtn}>Log Session</button>}
            </div>
        </Screen>
    )
}

export default LogSession