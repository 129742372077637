import React from "react"

import Colors from '../theme/Colors'

const SupportForm = ({ handleText, value, show, darkMode, handleSubmit }) => {
    return (
        <div style={{
            width: '100%',
            display: show ? 'flex' : 'none',
            flexDirection: 'column',
            backgroundColor: darkMode ? Colors.dark : Colors.stone
        }}>
            <textarea
                className={darkMode ? "textareaDark" : "textareaNorm"}
                placeholder="Say a word to the developer.."
                onChange={handleText}
                value={value}
                rows="15"
                style={{
                    padding:15,
                    fontSize:16,
                    width: '100%',
                    backgroundColor: darkMode ? Colors.dark : Colors.stone,
                    color: darkMode ? Colors.white : Colors.dark,
                }}

            ></textarea>
            <button className="button my-4 is-medium" style={{ width: '100%', backgroundColor: Colors.blue, color: Colors.white, alignSelf:'center' }} onClick={handleSubmit}>Submit</button>
        </div>
    )
}

export default SupportForm