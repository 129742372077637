import React, { useContext, useState} from 'react'
import { ThemeContext } from '../context/ThemeContext'
import { SessFiltersContext } from '../context/SessFiltersContext';

import NavBar from '../components/NavBar'
import Colors from '../theme/Colors'
import FilterOpts from './FilterOpts';



const Screen = props => {
    const [darkMode] = useContext(ThemeContext);
    const [filters] = useContext(SessFiltersContext)
    const [showMenu, setShowMenu] = useState(false)
    const handleMenu = () => {
        setShowMenu(val => !val)
        // if (!showMenu) return document.body.style.overflowY = 'hidden'
        // document.body.style.overflowY = 'scroll'
    }
    const align = props.align
    const justify = props.justify


    const styles = {
        screen: {
            height: props.fullHeight ? '100vh' : '100%',
            marginTop: props.nav ? 32 : 0,
            backgroundColor: darkMode ? Colors.dark : Colors.light,
            display:showMenu ? "none" : "flex"
        },
        // overflow: {
        //     overflowY: 'hidden'
        // }
    }
    return (
        <div style={styles.overflow}>
            <NavBar display={props.nav} title={props.name} menuClick={handleMenu} showMenu={showMenu} route={props.route}/>

           {filters.show 
            ? 
              <FilterOpts /> 
            : 
              <div className={`is-flex-direction-column ${align} ${justify} px-5`} style={styles.screen}>
                   {props.children}
               </div>}
        </div>
    )
}

export default Screen