import React from 'react';
import Chart from "react-apexcharts";
import { useMediaQuery } from 'react-responsive'
import Colors from '../../theme/Colors'
import Loading from '../Loading';
import NoData from '../NoData';

const LineChart = ({ chartData, darkMode }) => {
    const isDesktop = useMediaQuery({
        query: '(min-device-width: 1224px)'
      })
    //   console.log('cd',chartData)
    if (!chartData) return <Loading />
    if(chartData.series[0].data.length === 0) return <NoData darkMode={darkMode} />
    const xColor = () => {
        const total = chartData.series[0].data.length
        const darkModeArr = []
        for(let i=0; i<total; i++){
            const dark =  darkMode ? Colors.white : Colors.dark
            darkModeArr.push(dark)
        }
        return darkModeArr
    }
    const options = {
        labels: chartData.labels,
        colors: chartData.colors,
        stroke: {
            curve: 'smooth',
        },
        legend: {
            position: 'bottom',
            labels: {
                colors: darkMode ? Colors.white : Colors.dark,
                useSeriesColors: false
            }
        },
        dataLabels: {
            style: {
                colors: ['white']
            },
        },
        xaxis: {
            type: 'category',
            categories: [],
            labels: {
                show: true,
                rotate: -45,
                rotateAlways: false,
                hideOverlappingLabels: false,
                showDuplicates: false,
                trim: false,
                minHeight: undefined,
                maxHeight: 160,
                style: {
                    colors: xColor(),
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-xaxis-label',
                },
                offsetX: 0,
                offsetY: 0,
                format: undefined,
                formatter: undefined,
                datetimeUTC: true,
                datetimeFormatter: {
                    year: 'yy',
                    month: "MMM 'yy",
                    day: 'dd MMM',
                    hour: 'HH:mm',
                },
            },
        },
        yaxis: {
            catergories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            min: 0,
            max: 10,
            type: 'number',
            labels: {
                show: true,
                align: 'right',
                minWidth: 0,
                maxWidth: 160,
                style: {
                    colors: [darkMode ? Colors.white : Colors.dark],
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-yaxis-label',
                },
                offsetX: 0,
                offsetY: 0,
                rotate: 0,
                formatter: (val) => { return val },
            },
        }
    }
    return (
        <div className={`mixed-chart ${isDesktop ? 'px-5' :'px-0'}`}>
            <Chart
                options={options}
                series={chartData.series}
                type="line"
                width="100%"
                height="400px"
            />
        </div>
    )
}

export default LineChart