const EdibleSizes = [
    {id:1, text:'5', name:"5 mg", value:5, noImg:true},
    {id:2, text:'10', name:"10 mg", value:10, noImg:true},
    {id:3, text:'15', name:"15 mg", value:15, noImg:true},
    {id:4, text:'20', name:"20 mg", value:20, noImg:true},
    {id:5, text:'25', name:"25 mg", value:25, noImg:true},
    {id:6, text:'30', name:"30 mg", value:30, noImg:true},
    {id:7, text:'40', name:"40 mg", value:40, noImg:true},
    {id:8, text:'50', name:"50 mg", value:50, noImg:true},
    {id:9, text:'60', name:"60 mg", value:60, noImg:true},
    {id:10, text:'70', name:"70 mg", value:70, noImg:true},
    {id:11, text:'80', name:"80 mg", value:80, noImg:true},
    {id:12, text:'90', name:"90 mg", value:90, noImg:true},
    {id:13, text:'100', name:"100 mg", value:100, noImg:true},
    {id:14, text:'125', name:"125 mg", value:125, noImg:true},
    {id:15, text:'150', name:"150 mg", value:150, noImg:true},
    {id:16, text:'175', name:"175 mg", value:175, noImg:true},
    {id:17, text:'200', name:"200 mg", value:200, noImg:true},
    {id:18, text:'225', name:"225 mg", value:225, noImg:true},
    {id:19, text:'250', name:"250 mg", value:250, noImg:true},
    {id:20, text:'275', name:"275 mg", value:275, noImg:true},
    {id:21, text:'300', name:"300 mg", value:300, noImg:true},

];

export default EdibleSizes;