import React from 'react'
import Colors from '../theme/Colors'

const RadioButtonWrap = props => {
    const value = props.children.props.value
    const checked = props.checked
    const isChecked = value === checked ? true : false
    // console.log(props.children.props)
    const styles={
        container: {
            width: 26,
            height: 26,
            borderRadius:13,
            border:`1px solid ${props.darkMode ? Colors.stone:Colors.darkGray}`,
            backgroundColor: props.darkMode ? Colors.dark : Colors.light,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        checked: {
            backgroundColor: isChecked ? Colors.green : props.darkMode ? Colors.dark : Colors.light,
            height: 10,
            width: 10,
            borderRadius: 5,
        }
    }
    return (
        <div style={styles.container}>
            <div style={styles.checked}>
            {props.children}
            </div>
            
        </div>
    )
}

export default RadioButtonWrap