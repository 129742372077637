import nugXSmall from '../images/flower5.svg'
import nugSmall from '../images/flower4.svg'
import nugMedium from '../images/flower3.svg'
import nugLarge from '../images/flower2.svg'
import nugXLarge from '../images/flower.svg'

const WeedSizes = [
    {
        id: 1,
        name: 'Extra Small',
        img: nugXSmall,
        des: 'gets you really stoned',
        imgSize: {
            height:20,
            width: 20,
        }
    },
    {
        id: 2,
        name: 'Small',
        img: nugSmall,
        des: 'Be ready to blast off',
        imgSize: {
            height:25,
            width: 25,
        }
    },
    {
        id: 3,
        name: 'Medium',
        img: nugMedium,
        des: 'Eat or drink to get medicated',
        imgSize: {
            height:30,
            width: 30,
        }
    },
    {
        id: 4,
        name: 'Large',
        img: nugLarge,
        des: 'Other Types of weed',
        imgSize: {
            height:40,
            width: 40,
        }
    },
    {
        id: 5,
        name: 'Extra Large',
        img: nugXLarge,
        des: 'Other Types of weed',
        imgSize: {
            height:60,
            width: 60,
        }
    }
    ]
    
    export default WeedSizes;