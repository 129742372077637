import React, { useContext, useEffect, useState, useRef } from 'react';
import { ThemeContext } from '../context/ThemeContext'

import Colors from '../theme/Colors';

import WeedTypes from '../data/WeedTypes'
import SmokingMethods from '../data/SmokingMethods'
import DabSmokingMethods from '../data/DabSmokingMethods'
import EdibleMethods from '../data/EdibleMethods'

const SessionCard = props => {
    // eslint-disable-next-line
    const [darkMode, setDarkMode] = useContext(ThemeContext);
    const [showDelete, setShowDelete] = useState(false);
    const [dateTime, setDateTime] = useState({})
    const [methodImg, setMethodImg] = useState('')
    const [typeImg, setTypeImg] = useState('')
    const refNode = useRef();
    const delNode = useRef();

    const handleShowDelete = () => setShowDelete(pre => !pre)
        
    useEffect(() => {
        const weedsFilter = WeedTypes.filter(type => type.id === props.img.weedTypeId)
        const weeds = weedsFilter[0]
        setTypeImg(weeds.img)
        if (weeds.id === 1) {
            //flower
            const sM = SmokingMethods.filter(method => method.id === props.img.methodId)
            const flower = sM[0]
            setMethodImg(flower.img)
            return
        } else if (weeds.id === 2) {
            //concentrate
            const dM = DabSmokingMethods.filter(method => method.id === props.img.methodId)
            const dabs = dM[0]
            setMethodImg(dabs.img)
            return
        } else if (weeds.id === 3) {
            //edible
            const eM = EdibleMethods.filter(method => method.id === props.img.methodId)
            const edibles = eM[0]
            setMethodImg(edibles.img)
            return
        }
    // eslint-disable-next-line
    }, [])

    const handleClick = e => {
        // if(!showDelete) return;

        if (refNode.current.contains(e.target)) {
          // inside click
          return;
          // del btn was clicked
        } else if (delNode.current.contains(e.target)) {
            props.delete()
        }
        //outside click
        return setShowDelete(false)
      };

    // Listen for delete dismiss
    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    // eslint-disable-next-line
      }, []);

    useEffect(() => {
        const dateProp = new Date(props.date)
        const date = dateProp.toLocaleDateString()
        const time = dateProp.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })
        setDateTime(pre => ({ ...pre, date: date, time: time }))
        // eslint-disable-next-line
    }, [])
    const styles = {
        card: {
            height: '100%',
            width: '95%',
            minWidth:280,
            maxWidth: 325,
            backgroundColor: darkMode ? Colors.dark : Colors.stone,
            border: darkMode ? `3px solid ${Colors.white}` : null,
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
            // alignItems: 'center',
            borderRadius: 10,
            margin: '25px 10px',
            paddingBottom: 25,
            paddingRight:10,
            position: 'relative'
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px 15px',
            color: darkMode ? Colors.white : Colors.dark

        },
        icon: {
            color: darkMode ? Colors.white : Colors.darkGray
        },
        imgsHolder: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            margin: '15px 0'
        },
        img: {
            width: 50,
            height: 50,
            margin: '0 10px'
        },
        methodAndType: {
            color: darkMode ? Colors.white : Colors.dark,
            fontSize: 18,
            fontWeight: '600'
        },
        rating: {
            color: darkMode ? Colors.white : Colors.darkGray,
            fontSize: 56
        },
        ratingNum: {
            color: Colors.green,
            fontWeight: '700'
        },
        infoContainer: {
            textAlign: 'left',
            marginLeft: 30
        },
        infoList: {
            listStyle: 'circle',
        },
        listItem: {
            color: Colors.green,
            marginTop: 10,
            fontSize: 16,
        },
        infoItem: {
            color: darkMode ? Colors.white : Colors.dark,

        },
        listItemValue: {
            fontWeight: 900,
            color: Colors.green
        },
        listItemNote: {
            fontWeight: 600,
            color: darkMode ? Colors.white : Colors.dark,
        },
        timeHolder: {
            backgroundColor: Colors.green,
            marginLeft: -15,
            marginTop: -5,
            borderTopLeftRadius: 7,
            borderBottomRightRadius: 7,
            color: Colors.white,
            padding: 10,
            fontWeight: '600'
            // border:darkMode ? `1px solid ${Colors.white}` : null,
        },
        deleteHolder: {
            width: 75,
            height: 35,
            backgroundColor: darkMode ? Colors.dark : Colors.white,
            border: darkMode ? `1px solid ${Colors.white}` : null,
            borderRadius: 5,
            justifyContent: 'center',
            alignItems: 'center',
            display: showDelete ? 'flex' : 'none',
            position: 'absolute',
            right: 5,
            top: 45,
            zIndex:99,
            cursor:"pointer"
        },
        time: {
            fontWeight: 700,
        },
        date: {
            fontWeight: 700,
        }

    }

    return (
        <div style={styles.card} >
            <div style={styles.header}>
                <div style={styles.timeHolder}>
                    <p style={styles.date}>{dateTime.date}</p>
                    <p style={styles.time}>{dateTime.time}</p>
                </div>
                <div ref={refNode} className="is-flex is-align-items-flex-end is-flex-direction-column is-justify-content-center">
                    <div style={{ width: 50, textAlign: 'right' }} onClick={handleShowDelete}>
                        <span><i style={styles.icon} className="pr-2 fas fa-ellipsis-h fa-1x"></i></span>
                    </div>
                </div>
                <div style={styles.deleteHolder} onClick={props.delete} ref={delNode}>
                    <p className="has-text-danger has-text-weight-bold">Delete</p>
                </div>
            </div>
            <div style={styles.imgsHolder}>
                <img src={typeImg} style={styles.img} alt="type" />
                <img src={methodImg} style={styles.img} alt="method" />
            </div>
            <div className="is-flex is-justify-content-center my-1 px-1"><p style={styles.methodAndType}>{props.weedType} + {props.method}</p></div>
            <div className="is-flex is-justify-content-center my-2 px-1">
                <p style={styles.rating}>[ <span style={styles.ratingNum}>{props.rating}</span> ]</p>
            </div>

            <div style={styles.infoContainer}>
                <ul style={styles.infoList}>
                    <li style={styles.listItem}>
                        <span style={styles.infoItem} >Duration: </span>
                        <span style={styles.listItemValue}>{props.time}</span>
                    </li>
                    <li style={styles.listItem}>
                        <span style={styles.infoItem}>Size:</span>
                        <span style={styles.listItemValue}> {props.size}</span>
                    </li>
                    <li style={styles.listItem}>
                        <span style={styles.infoItem} >Notes:</span>  <span style={styles.listItemNote}>{props.notes} </span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default SessionCard