import React from 'react'

import Colors from '../theme/Colors'


const ListItem = props => {
    // eslint-disable-next-line

    return (
        <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 55,
            paddingLeft: 10,
            paddingRight: 10,
            marginBottom: 5,
            backgroundColor: props.darkMode ? Colors.dark : Colors.stone,
            border: props.darkMode ? `2px solid ${Colors.stone}` : null,
            borderRadius: 5


        }}
            onClick={props.onClick}
        >
            <p style={{
                color: props.darkMode ? Colors.white : Colors.lightGray,
                fontWeight: '700'
            }}>{props.name}</p>
            <i className={props.show ? "fas fa-chevron-up" : "fas fa-chevron-down"}
                style={{
                    color: props.darkMode ? Colors.white : Colors.lightGray
                }}></i>
        </div>
    )
}

export default ListItem