export const timeFunctionsArr = [
        {
            id:1,
            display:"Today",
            value:"today", 
        },
        {
            id:2,
            display:"Weekly",
            value:"weekly",
        },
        {
            id:3,
            display:"Monthly",
            value:"monthly",
        },
        {
            id:4,
            display:"All Time",
            value:"allTime",            
        },

]

export const methodArrRadio = [
    {
        id:5,
        display:"By Rating",
        value:"rate",
        data: {
            flowerMeth:"Flower",
            concentrateMeth:"Concentrate",
            edibleMeth:"Edible"
        }
    },
    {
        id:6,
        display:"By Time",
        value:"time",
        data: {
            flowerMeth:"Flower",
            concentrateMeth:"Concentrate",
            edibleMeth:"Edible"
        }
    },
]

export const filterTypeArrRadio = [
    {
        id:6,
        display:"All",
        value:"all",
    },
    {
        id:7,
        display:"Flower",
        value:"flower",

    },
    {
        id:8,
        display:"Concentrate",
        value:"concentrate",
    },
    {
        id:9,
        display:"Edible",
        value:"edible",

    },
]
