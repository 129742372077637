import React, { useContext, useEffect, useState } from "react"
import { FirebaseContext } from '../context/FirebaseContext'
import { ThemeContext } from '../context/ThemeContext'
import { useLocation, useHistory, Link } from "react-router-dom";

import { handlePassword } from "../helpers/signup";
import Colors from "../theme/Colors"

const ResetPW = props => {
    const [firebase] = useContext(FirebaseContext)
    // eslint-disable-next-line
    const [darkMode, setDarkMode] = useContext(ThemeContext);
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [code, setCode] = useState('')
    const [linkToPwReset, setLinkToPwReset] = useState(false)
    const [successMsg, setSuccessMsg] = useState('')
    const location = useLocation()
    const history = useHistory()

    //mode=resetPassword&oobCode=l2qCPqBLhpL9QZwlE_CikvNd_qm1vY9_i8GZL3QEfCsAAAF4mbUwTQ&apiKey=AIzaSyBPPtasVMyrJiBxhZlU-5el-HdaZZjdPqM&lang=en 
    useEffect(() => {
        // get the search string
        const path = location.search
        //split into array at code, 2nd array contains the code / is in the correct start
        const codeArr1 = path.split("Code=")
        //split the array to get the end of the array
        const codeArr2 = codeArr1[1].split("&")
        //your left with the code 
        const code = codeArr2[0]
        setCode(code)

    // eslint-disable-next-line
    }, [])
    const onChange = e => setPassword(e.target.value)

    const onSubmit = () => {
        const isPassword = handlePassword(password)
        if (!isPassword.valid) return setError(isPassword.msg)
        firebase.auth().confirmPasswordReset(code, password)
          .then(function () {
              // Success
            setSuccessMsg('Password Reset! Redirecting to login...')
             setTimeout(() => {
                history.push('/login')
             }, 3500) 
          })
          .catch(function (err) {
              // Invalid code
              console.log(err)
              if(err.message ===  "The action code has expired. "){
                setError("Code has expired. Please get a new code.")
                setLinkToPwReset(true)
                return
              }
              setError('Something went wrong. Please try getting a new code.')
              setLinkToPwReset(true)
          })
    }

    const styles = {
        input: {
            backgroundColor: darkMode ? Colors.dark : Colors.light,
            color: darkMode ? Colors.stone : Colors.darkGray,
            border: darkMode ? `1px solid ${Colors.stone}` : null,
            height: 45,
            width: "100%",
            paddingLeft: 45,
            fontSize: 17,
        },
        error: {
            color: Colors.red,
            textAlign: "center",
            fontSize: 16,
            fontWeight: "700",
        },
        form: {
            padding: "0 15px"
        },
        success:{
            color:Colors.green,
            textAlign:"center",
            opacity:successMsg ? 1 : 0,
            display:error ? 'none' : 'block',
            height:40
        },
        link:{
            textAlign:"center",
            margin:"5px 0",
            
        }
    }
    return (
        <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center" style={{ height: "100vh" }}>
            <div style={styles.form}>
                <p className="my-3" style={styles.error}>{error}</p>
                <p className="my-3" style={styles.success}>{successMsg}</p>
                {linkToPwReset ? <div style={styles.link}><Link to="/passwordreset"style={{color:Colors.green, fontWeight:"600"}} >Get New Reset Link</Link></div> : null}
                <p className="control has-icons-left has-icons-right">
                    <input
                        className={darkMode ? "inputDark" : "input"}
                        style={styles.input}
                        name="name"
                        type="password"
                        placeholder="New Password"
                        onChange={onChange}
                        value={password}
                    />
                    <span className="icon is-small is-left" style={{ height: "100%" }}>
                        <i className="fas fa-lock"></i>
                    </span>
                </p>
                <button className="button is-primary my-3" style={{ width: '100%' }} onClick={onSubmit}>Submit</button>
            </div>
        </div>
    )
}

export default ResetPW