import React, { useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext'

import Colors from '../theme/Colors';

const Card = props => {
    // eslint-disable-next-line
    const [darkMode, setDarkMode] = useContext(ThemeContext);

    const styles = {
        card: {
            height: 125,
            width: 100,
            backgroundColor: darkMode ? Colors.dark : Colors.stone,
            border: darkMode ? `1px solid ${Colors.white}` : null,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 8,
            margin: '5px 10px'
        },
        text: {
            marginTop: 5,
            color:darkMode ? Colors.white : Colors.dark
        },
        value: {
            fontSize: 36,
            fontWeight: 'bold',
            color:Colors.green
        },
        img: { 
            width: props.imgSize ? props.imgSize.width : 50, 
            height: props.imgSize ? props.imgSize.height : 50 
        }
    }

    return (
        <div style={styles.card} onClick={props.onClick}>
            {props.noImg ? <p style={styles.value}>{props.val}</p> :
            <img src={props.img} style={styles.img} alt={props.alt}/>}
            <p style={styles.text}>{props.name}</p>
        </div>
    )
}

export default Card