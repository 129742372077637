import React, { useContext } from 'react'
import { ThemeContext } from '../context/ThemeContext';
// import { FirebaseContext } from '../context/FirebaseContext'
import { Link } from "react-router-dom";
import Switch from "react-switch";
import Colors from '../theme/Colors';

const Menu = props => {
    const [darkMode, setDarkMode] = useContext(ThemeContext);
    // const [firebase] = useContext(FirebaseContext)
    const screenHeight = window.innerHeight

    const handleDarkMode = () => {
        setDarkMode(val => !val)
        if (darkMode) {
            document.body.style.backgroundColor = Colors.light
        } else {
            document.body.style.backgroundColor = Colors.dark
        }
    }

    const styles = {
        screen: {
            width: '100vw',
            height: '100vh',
            zIndex: 99,
            position: 'relative',
            // overflowY: 'hidden',
        },
        aside: {
            width: "100%",
            // marginTop: 30
        },
        text: {
            color: darkMode ? Colors.white : Colors.dark,
            paddingLeft: 15,
            fontSize: 18,
            fontWeight: '600'
        },
        icon: {
            color: darkMode ? Colors.white : Colors.dark,
        },
        close: {
            color: darkMode ? Colors.white : Colors.darkGray,
            position: 'absolute',
            right: 13,
            top: 16,
            fontSize: 22,

        },
        logOutBtn: {
            position: 'absolute',
            bottom: 5,
            left: 15,
            color: darkMode ? Colors.white : Colors.dark
        },
        menuList: {
            display: 'flex',
            flexDirection: 'column',
            height: screenHeight,
            justifyContent: 'space-evenly',
            paddingLeft: 25
        },
        home: {
            color: props.title === 'Higher Data' ? Colors.green : darkMode ? Colors.white : Colors.dark
        },
        logSess: {
            color: props.title === 'Log Session' ? Colors.green : darkMode ? Colors.white : Colors.dark
        },
        mydata: {
            color: props.title === 'My Data' ? Colors.green : darkMode ? Colors.white : Colors.dark
        },
        mySess: {
            color: props.title === 'My Sessions' ? Colors.green : darkMode ? Colors.white : Colors.dark
        },
        settings: {
            color: props.title === 'Settings' ? Colors.green : darkMode ? Colors.white : Colors.dark
        },
    }
    return (
        <div style={styles.screen}>
            <aside className="menu" style={styles.aside}>
                <ul className="menu-list" style={styles.menuList}>
                    <Link to="/" className="routerLink" onClick={props.menuDisplay}>
                        <li className="is-flex is-align-items-center">
                            <i style={{ ...styles.icon, ...styles.home }} className="pr-2 fas fa-home fa-1x"></i>
                            <span style={{ ...styles.text, ...styles.home }}>Home</span>
                        </li>
                    </Link>
                    <Link to="logsession" className="routerLink" onClick={props.menuDisplay}>
                        <li className="is-flex is-align-items-center">
                            <i style={{ ...styles.icon, ...styles.logSess }} className="pr-2 fas fa-plus fa-1x"></i>
                            <span style={{ ...styles.text, ...styles.logSess }}>Log Session</span>
                        </li>
                    </Link>
                    <Link to="mydata" className="routerLink" onClick={props.menuDisplay}>
                        <li className="is-flex is-align-items-center">
                            <i style={{ ...styles.icon, ...styles.mydata }} className="pr-2 fas fa-chart-bar fa-1x"></i>
                            <span style={{ ...styles.text, ...styles.mydata }}>My Data</span>
                        </li>
                    </Link>
                    <Link to="mysessions" className="routerLink" onClick={props.menuDisplay}>
                        <li className="is-flex is-align-items-center">
                            <i style={{ ...styles.icon, ...styles.mySess }} className="pr-2 fas fa-calendar fa-1x"></i>
                            <span style={{ ...styles.text, ...styles.mySess }}>My Sessions</span></li>
                    </Link>
                    <Link to="settings" className="routerLink" onClick={props.menuDisplay}>
                        <li className="is-flex is-align-items-center">
                            <i style={{ ...styles.icon, ...styles.settings }} className="pr-2 fas fa-cog fa-1x"></i>
                            <span style={{ ...styles.text, ...styles.settings }}>Settings</span>
                        </li>
                    </Link>

                    <li onClick={handleDarkMode} className="is-flex is-align-items-center">
                        <i style={styles.icon} className="pr-4 fas fa-moon fa-1x"></i>
                        {/* <span style={styles.text}>Dark Mode {darkMode ? 'off' : 'on'}</span> */}
                        <Switch onChange={handleDarkMode} checked={darkMode} uncheckedIcon onColor={Colors.green} />
                    </li>
                    <div style={styles.closeWrapper} onClick={props.menuDisplay}>
                        <i style={styles.close} className="pr-2 fas fa-times" ></i>
                    </div>
                </ul>
            </aside>
        </div>
    )
}

export default Menu
