import React, { useContext, forwardRef } from 'react';
import { ThemeContext } from '../context/ThemeContext'
import DatePicker from "react-datepicker";
// import Input from "../components/forms/Input"

import "react-datepicker/dist/react-datepicker.css";
import Colors from '../theme/Colors';


const DateTime = ({ startDate, setStartDate }) => {
    const [darkMode] = useContext(ThemeContext);
    const CustomInput = forwardRef(
        ({ value, onClick }, ref) => (
          <button style={styles.input} onClick={onClick} ref={ref}>
            {value}
          </button>
        ),
      );

    const styles = {
        wrapper:{
            width: '100%',
            backgroundColor: darkMode ? Colors.dark : Colors.light,
            color: darkMode ? Colors.white : Colors.dark,
            margin: "25px 0"
        },
        input: {
            backgroundColor: darkMode ? Colors.dark : Colors.light,
            border:'none',
            color: darkMode ? Colors.white : Colors.dark,
            width: '100%',
            padding: 10,
            fontSize: 18,
            borderRadius: 10,
            outline: 'none'
        },
    }

    return (
        <div style={styles.wrapper}>
            <p style={styles.label}>Date & Time</p>
            <DatePicker 
                selected={startDate} 
                onChange={date => setStartDate(date)} 
                showTimeSelect 
                dateFormat="MMMM d, yyyy h:mm aa"
                maxDate={new Date()}
                customInput={<CustomInput />}
            />
        </div>
    )
}

export default DateTime