import bong from '../images/bong.svg'
import pipe from '../images/pipe.svg'
import blunt from '../images/blunt.svg'
import joint from '../images/joint.svg'
import bubbler from '../images/bubbler.svg'
import oneHitter from '../images/oneHitter.svg'
import vaporizer from '../images/vaporizer.svg'
import gravityBong from '../images/gravity.svg'

const smokingMethods = [
    {
        id: 1,
        name: 'Bong',
        img: bong,
        des: 'gets you really stoned',
    },
    {
        id: 2,
        name: 'Pipe',
        img: pipe,
        des: 'good for sharing',
    },
    {
        id: 3,
        name: 'Blunt',
        img: blunt,
        des: 'dutchmasters chocolate is my fav',
    },
    {
        id: 4,
        name: 'Joint',
        img: joint,
        des: 'hippy dippy',
    },
    {
        id: 5,
        name: 'Bubbler',
        img: bubbler,
        des: 'small little bong like',
    },
    {
        id: 6,
        name: 'One Hitter',
        img: oneHitter,
        des: 'small little bong like',
    },
    {
        id: 7,
        name: 'Vaporizer',
        img: vaporizer,
        des: 'clean and enjoyable session',
    },
    {
        id: 8,
        name: 'Gravity Bong',
        img: gravityBong,
        des: 'get ready to be rocked!',
    }
    ]
    
export default smokingMethods;