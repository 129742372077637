import React from 'react'
import Colors from '../../theme/Colors'

const TitleChart = ({title, darkMode}) => {
    const styles = {
        text: {
            color: darkMode ? Colors.white : Colors.dark,
            fontWeight: 700,
        }
    }
    return (
        <div className="my-3 has-text-centered">
            <p style={styles.text}>{title}</p>
        </div>
    )
}

export default TitleChart