import React, { useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext'
import { SessFiltersContext } from '../context/SessFiltersContext';
import RadioGroup from "../components/RadioGroup"
import Colors from '../theme/Colors';
import MinMax from './MinMax';

const FilterOpts = props => {
    // eslint-disable-next-line
    const [darkMode, setDarkMode] = useContext(ThemeContext);
    const [filters, setFilters] = useContext(SessFiltersContext)

    const rateMinUp = () => {
        if(filters.rateMin === filters.rateMax) return 
        setFilters(pre => ({...pre, rateMin:pre.rateMin + 1}))
    }
    const rateMinDown = () => {
        if(filters.rateMin - 1 === 0) return
        setFilters(pre => ({...pre, rateMin:pre.rateMin - 1}))
    }
    const rateMaxUp = () => {
        if(filters.rateMax + 1 === 11) return
        setFilters(pre => ({...pre, rateMax:pre.rateMax + 1}))
    }
    const rateMaxDown = () => {
        if(filters.rateMin === filters.rateMax) return 
        setFilters(pre => ({...pre, rateMax:pre.rateMax - 1}))

    }
    const timeMinUp = () => {
        if(filters.timeMin + 1 === filters.timeMax) return 
        setFilters(pre => ({...pre, timeMin:pre.timeMin + 1}))
    }
    const timeMinDown = () => {
        if(filters.timeMin - 1 === -1) return
        setFilters(pre => ({...pre, timeMin:pre.timeMin - 1}))
    }
    const timeMaxUp = () => {
        if(filters.timeMax + 1 === 13) return
        setFilters(pre => ({...pre, timeMax:pre.timeMax + 1}))
    }
    const timeMaxDown = () => {
        if(filters.timeMin + 1 === filters.timeMax) return 
        setFilters(pre => ({...pre, timeMax:pre.timeMax - 1}))

    }

    const handleSort = (e, val) => setFilters(pre => ({...pre, sort:val}))
    

    const styles = {
        container: {
            backgroundColor: darkMode ? Colors.dark : Colors.light,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // margin: '5px 10px'
        },
        header: {
            height: 70,
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            borderBottom: `2px solid ${darkMode ? Colors.stone : Colors.lightGray}`,
            marginBottom: 25,
        },
        typeH: {
            color: Colors.green,
        },
        ratingH: {
            color: Colors.green,
        },
        timeH: {
            color: Colors.green,
        },
        typesContainer:{
            width: '100%',
            textAlign: 'left',
            marginBottom: 30,
        },
        text: {
            paddingLeft: 30,
            marginBottom: 5,
            color: Colors.blue
        },
        value: {
            fontSize: 36,
            fontWeight: 'bold',
            color: Colors.green
        },
        img: {
            width: props.imgSize ? props.imgSize.width : 50,
            height: props.imgSize ? props.imgSize.height : 50
        },
        sliderContainer:{
            width: '100%',
            margin: "30px 0"
        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <p style={styles.typeH}>Type: {filters.types}</p>
                <p style={styles.ratingH}>Rating: {filters.rateMin} to {filters.rateMax} {filters.sort === 'rateAsc' ? '(ASC)' : null}{filters.sort === 'rateDesc' ? '(DESC)' : null}</p>
                <p style={styles.timeH}>Time: {filters.timeMin} to {filters.timeMax} {filters.sort === 'timeAsc' ? '(ASC)' : null}{filters.sort === 'timeDesc' ? '(DESC)' : null}</p>
            </div>
            <div style={styles.typesContainer}>
                <p style={styles.text}>Type</p>
                <RadioGroup
                    onChange={(e) => setFilters(pre => ({...pre,  types:e.target.value})) } 
                    radioVals="filterTypes" 
                    darkMode={darkMode} name="typeRadio" 
                    currentVal={filters.types}
                />
            </div>
            <div style={styles.sliderContainer}>
            <p style={styles.text}>Rating</p>
                <MinMax 
                    darkMode={darkMode} 
                    min={filters.rateMin} 
                    max={filters.rateMax}
                    handleMinUp={rateMinUp} 
                    handleMinDown={rateMinDown}
                    handleMaxUp={rateMaxUp}
                    handleMaxDown={rateMaxDown}
                    handleSort={handleSort}
                    asc="rateAsc"
                    desc="rateDesc"
                    sortVal={filters.sort}
                />
            </div>
            <div style={styles.sliderContainer}>
            <p style={styles.text}>Time</p>
                <MinMax 
                    darkMode={darkMode} 
                    min={filters.timeMin} 
                    max={filters.timeMax}
                    handleMinUp={timeMinUp} 
                    handleMinDown={timeMinDown}
                    handleMaxUp={timeMaxUp}
                    handleMaxDown={timeMaxDown}
                    handleSort={handleSort}
                    asc="timeAsc"
                    desc="timeDesc"
                    sortVal={filters.sort}
                />
            </div>
            <div style={{textAlign:'center', width: '100%', color:Colors.pink}} onClick={() => setFilters(pre => {
                return {
                    ...pre,
                    rateMin:1,
                    rateMax:10,
                    timeMin:0,
                    timeMax:12,
                    sort:"none",
                    types:"all"

                }
            })}>Reset Filters</div>
        </div>
    )
}

export default FilterOpts