import React, { useState, useContext, useEffect } from 'react'
import { EnvironmentContext } from '../context/EnvironmentContext'
import { FirebaseContext } from '../context/FirebaseContext'
import { UserContext } from '../context/UserContext'
import { useHistory, withRouter } from "react-router-dom";
import Loading from './Loading';

const PrivateRoute = (props) => {
  const [environment] = useContext(EnvironmentContext)
  const [firebase] = useContext(FirebaseContext)
  // eslint-disable-next-line
  const [user, setUser] = useContext(UserContext)
  const [loggedIn, setLoggedIn] = useState(false)
  const history = useHistory();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (userFire) {
      if (userFire) {
        // User is signed in.
        firebase.database().ref(`${environment}/users/${userFire.uid}/profile`).once('value', snapshot => {
          const profile = snapshot.val()
          setUser(profile)
          setLoggedIn(true)
        })
      } else {
        // No user is signed in.
        const newUser = localStorage.getItem('newUser')
        if(newUser === "false") return history.push('/login')
        return history.push('/welcome')
      }
    })
      // eslint-disable-next-line
  }, [])

  if (!loggedIn) {
    return (
      <div className="is-flex is-justify-content-center is-align-items-center px-2" style={{height: '100vh'}}>
        <Loading />
      </div>
    )
  }

  return <div>{props.children}</div>
}

export default withRouter(PrivateRoute)