import React from 'react';
import Chart from "react-apexcharts";
import { useMediaQuery } from 'react-responsive'
import Colors from '../../theme/Colors'
import Loading from '../Loading';
import NoData from '../NoData';

const BarChart = ({chartData, darkMode}) => {
    const isDesktop = useMediaQuery({
        query: '(min-device-width: 1224px)'
      })
      const xColor = () => {
        const total = chartData.series[0].data.length
        const darkModeArr = []
        for(let i=0; i<total; i++){
            const dark =  darkMode ? Colors.white : Colors.dark
            darkModeArr.push(dark)
        }
        return darkModeArr
    }
    if(!chartData) return <Loading /> 
    if(chartData.series[0].data.length === 0) return <NoData darkMode={darkMode}/>

    const options = {
        chart: {
            type: 'bar'
          },
        labels:chartData.labels,
        colors: chartData.colors,
        plotOptions: {
            bar: {
                columnWidth: "35%",
            },
            yaxis: {
                catergories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            }
        },
        legend: {
            position:'bottom',
            labels: {
                colors: darkMode ? Colors.white : Colors.dark,                
            }
        },
        xaxis: {
            type: 'category',
            categories: [],
            labels: {
                show: true,
                rotate: -45,
                rotateAlways: false,
                hideOverlappingLabels: false,
                showDuplicates: false,
                trim: false,
                minHeight: undefined,
                maxHeight: 160,
                style: {
                    colors: xColor(),
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-xaxis-label',
                },
                offsetX: 0,
                offsetY: 0,
                format: undefined,
                formatter: undefined,
                datetimeUTC: true,
                datetimeFormatter: {
                    year: 'yy',
                    month: "MMM 'yy",
                    day: 'dd MMM',
                    hour: 'HH:mm',
                },
            },
        },
        yaxis: {
            min: 1,
            max: chartData.key === "rate" ? 10 : 12,
            // tickAmount:10,
            forceNiceScale:true,
            type: 'number',
            title: {
                text: chartData.key === "rate" ? "Rating" :"Hours",
                style: {
                    color: darkMode ? Colors.white : Colors.darkGray,
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    cssClass: 'apexcharts-yaxis-title',
                },
            },
            labels: {
                show: true,
                align: 'right',
                minWidth: 0,
                maxWidth: 160,
                style: {
                    colors: [darkMode ? Colors.white : Colors.dark],
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-yaxis-label',
                },
                offsetX: 0,
                offsetY: 0,
                rotate: 0,
                formatter: (val) => { return val },
            },
        }
    }
    return (
        <div className="mixed-chart is-flex is-justify-content-center">
            <Chart
                options={options}
                series={chartData.series}
                type="bar"
                width={isDesktop ? 500 : "100%"}
                height={300}
            />
        </div>
      )
}

export default BarChart