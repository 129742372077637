import React, { useState, useContext, useEffect } from 'react'
import { EnvironmentContext } from '../context/EnvironmentContext'
import { FirebaseContext } from '../context/FirebaseContext'
import { UserContext } from '../context/UserContext'
import { SessFiltersContext } from '../context/SessFiltersContext'
import Screen from '../components/Screen'
import SessionCard from '../components/SessionCard'
import { handleFiltering } from '../dataCalculations/sessionsCalcs'
import NoData from '../components/NoData'

const MySessions = props => {
    const [environment] = useContext(EnvironmentContext)
    const [firebase] = useContext(FirebaseContext)
        // eslint-disable-next-line
    const [filters, setFilters] = useContext(SessFiltersContext)
    const [sessions, setSessions] = useState([])
    const [allSessions, setAllSessions] = useState([])
    // eslint-disable-next-line
    const [user, setUser] = useContext(UserContext)
    useEffect(() => {
        firebase.database().ref(`${environment}/users/${user.uid}/sessions`).once('value', snapshot => {
            const sessionsData = snapshot.val()
            if(!sessionsData) return;
            const sortSessions = Object.values(sessionsData).sort((x,y) => y.date - x.date)
            setSessions(sortSessions)
            setAllSessions(sortSessions)
        })
    // eslint-disable-next-line
    }, [])
    const deleteSession = (id) => {
        firebase.database().ref(`${environment}/users/${user.uid}/sessions/${id}`).remove().then(result => {
            const removeSession = sessions.filter(ses => ses.date !== id)
            setSessions(removeSession)
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        // function should filter types then listen for changes
        // console.log('type changed', filters.types)
        const filterSessions = handleFiltering(allSessions, filters)
        setSessions(filterSessions)
        // console.table(filterSessions)

        // eslint-disable-next-line
    }, [filters.types, filters.sort, filters.rateMax, filters.rateMin, filters.timeMin, filters.timeMax])
    return (
        <Screen align="is-align-items-center" justify="is-justify-content-center" nav={true} name="My Sessions" route="MySessions">
            {sessions.length === 0 ? <NoData /> :
            <div className="is-flex is-justify-content-space-evenly is-flex-wrap-wrap">
            {sessions.map(card => (
                <SessionCard 
                    id={card.date}
                    key={card.date}
                    rating={card.ratingValue}
                    weedType={card.weedTypeName}
                    method={card.methodName}
                    notes={card.notes}
                    date={card.date}
                    img={card}
                    size={card.weedSizeName}
                    time={card.timeDisplayName}
                    delete={() => deleteSession(card.date)}
                />))}
                </div>}
        </Screen>
    )

}

export default MySessions