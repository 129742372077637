import React from 'react';
import Chart from "react-apexcharts";
import { useMediaQuery } from 'react-responsive'
import Colors from '../../theme/Colors'
import Loading from '../Loading';
import NoData from '../NoData';

const PieChart = ({chartData, darkMode}) => {
    const isDesktop = useMediaQuery({
        query: '(min-device-width: 1224px)'
      })
    if(!chartData) return <Loading />
    if(chartData.series.length === 0) return <NoData darkMode={darkMode} />

    const options = {
        labels:chartData.labels,
        colors: [Colors.green, Colors.blue, Colors.pink],
        plotOptions: {
            pie: {
                donut: {
                    size: '25%'
                }
            }
        },
        legend: {
            position:'bottom',
            labels: {
                colors: darkMode ? Colors.white : Colors.dark,
                
            }
        },
    }
    return (
        <div className="mixed-chart is-flex is-justify-content-center">
            <Chart
                options={options}
                series={chartData.series}
                type="donut"
                width={isDesktop ? 500 : "100%"}
            />
        </div>
      )
}

export default PieChart