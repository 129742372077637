import React, { createContext, useState } from 'react';
// import Colors from '../theme/Colors'

export const SessFiltersContext = createContext();

export const SessFiltersProvider = props => {
    const [filters, setFilters] = useState({
        show: false,
        types: "all",
        rateMin: 1,
        rateMax: 10,
        timeMin: 0,
        timeMax: 12,
        sort:"none"
        });

    return (
        <SessFiltersContext.Provider value={[filters, setFilters]}>
            {props.children}
        </SessFiltersContext.Provider>
    )
}