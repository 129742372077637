import React, { useContext, useEffect, useState } from 'react'
import { EnvironmentContext } from '../context/EnvironmentContext'
import { ThemeContext } from '../context/ThemeContext'
import { FirebaseContext } from '../context/FirebaseContext'
import { UserContext } from '../context/UserContext'
import { useMediaQuery } from 'react-responsive'

import Screen from '../components/Screen'
import StatCard from '../components/StatCard'

import { weedTypesDonut, toleranceBreakDays, allTimeSessions, monthlySessionsDates, todaySessionsDates, weeklySessionsDates, ratingLineChart, flowerMethodBar, edibleMethodBar, concentrateMethodBar, floTimeMethodBar, waxTimeMethodBar, edibleTimeMethodBar } from '../dataCalculations/sessionsCalcs'
import { formatSessions } from '../helpers/firebaseHelpers'
import PieChart from '../components/charts/PieChart'
import RadioGroup from '../components/RadioGroup'
import LineChart from '../components/charts/LineChart'
import TitleChart from '../components/charts/TitleChart'
import BarChart from '../components/charts/BarChart'

const MyData = props => {
    const [environment] = useContext(EnvironmentContext)
    const [firebase] = useContext(FirebaseContext)
    // eslint-disable-next-line
    const [darkMode, setDarkMode] = useContext(ThemeContext);
    // eslint-disable-next-line
    const [user, setUser] = useContext(UserContext)
    const isMobile = useMediaQuery({
        query: '(max-width: 870px)'
    })
    const [sessionsInfo, setSessionsInfo] = useState({})
    const [timeState, setTimeState]= useState({pie:"allTime", line:"allTime", rateTimeFlo:"rate", rateTimeWax:"rate", rateTimeEdi:"rate"});

    // get today sessions
    useEffect(() => {
        const today = todaySessionsDates()
        firebase.database().ref(`${environment}/users/${user.uid}`)
            .child("sessions")
            .orderByChild('date')
            .startAt(today.startAt)
            .endAt(today.endAt)
            .get()
            .then(snap => {
                const data = snap.val()
                if (!data) return setSessionsInfo(pre => ({ ...pre, today: 0 }))
                const sess = formatSessions(data)
                setSessionsInfo(pre => ({ ...pre, today: sess.length, todayData: sess }))
            }).catch(err => console.log(err))
        // eslint-disable-next-line
    }, [])
    //get weekly sessions
    useEffect(() => {
        const weekly = weeklySessionsDates()
        firebase.database().ref(`${environment}/users/${user.uid}`)
            .child("sessions")
            .orderByChild('date')
            .startAt(weekly.startAt)
            .endAt(weekly.endAt)
            .get()
            .then(snap => {
                const data = snap.val()
                if (!data) return setSessionsInfo(pre => ({ ...pre, weekly: 0 }))
                const sess = formatSessions(data)
                setSessionsInfo(pre => ({ ...pre, weekly: sess.length, weeklyData: sess }))
            }).catch(err => console.log(err))
        // eslint-disable-next-line
    }, [])

    //get monthly sessions
    useEffect(() => {
        const monthly = monthlySessionsDates()
        firebase.database().ref(`${environment}/users/${user.uid}`)
            .child("sessions")
            .orderByChild('date')
            .startAt(monthly.startAt)
            .endAt(monthly.endAt)
            .get()
            .then(snap => {
                const data = snap.val()
                if (!data) return setSessionsInfo(pre => ({ ...pre, monthly: 0 }))
                const sess = formatSessions(data)
                setSessionsInfo(pre => ({ ...pre, monthly: sess.length, monthlyData: sess }))
            }).catch(err => console.log(err))
        // eslint-disable-next-line
    }, [])

    //get allTime sessions
    useEffect(() => {
        const allTime = allTimeSessions()
        firebase.database().ref(`${environment}/users/${user.uid}`)
            .child("sessions")
            .orderByChild('date')
            .startAt(allTime.startAt)
            .endAt(allTime.endAt)
            .get()
            .then(snap => {
                const data = snap.val()
                if (!data) return setSessionsInfo(pre => ({ ...pre, allTime: 0, tolerance: 0 }))
                const sess = formatSessions(data)
                const tolerance = toleranceBreakDays(sess)
                const types = weedTypesDonut(sess)
                const ratings = ratingLineChart(sess)
                const flowerMe = flowerMethodBar(sess)
                const edibleMe = edibleMethodBar(sess)
                const concentrateMe = concentrateMethodBar(sess)
                setSessionsInfo(pre => ({ ...pre, allTime: sess.length, tolerance: tolerance, allTimeData: sess, weedTypes: types, ratings: ratings, fm:flowerMe, em:edibleMe, cm:concentrateMe }))
            }).catch(err => console.log(err))
        // eslint-disable-next-line
    }, [])
    const handlePieChange = (e) => {
        console.log('changed')
        const val = e.target.value
        let newVal;
        if (val === 'today') {
            //set types for today
            newVal = weedTypesDonut(sessionsInfo.todayData)
            setSessionsInfo(pre => ({ ...pre, weedTypes: newVal }))
            setTimeState(pre => ({...pre, pie:"today"}))
        } else if (val === 'weekly') {
            //set tpyes for weekly
            newVal = weedTypesDonut(sessionsInfo.weeklyData)
            setSessionsInfo(pre => ({ ...pre, weedTypes: newVal }))
            setTimeState(pre => ({...pre, pie:"weekly"}))
        } else if (val === 'monthly') {
            //set types for monthly
            newVal = weedTypesDonut(sessionsInfo.monthlyData)
            setSessionsInfo(pre => ({ ...pre, weedTypes: newVal }))
            setTimeState(pre => ({...pre, pie:"monthly"}))
        } else if (val === 'allTime') {
            //set types for all time
            newVal = weedTypesDonut(sessionsInfo.allTimeData)
            setSessionsInfo(pre => ({ ...pre, weedTypes: newVal }))
            setTimeState(pre => ({...pre, pie:"allTime"}))
        }
        // console.log(val)
    }
    const handleLineChange = (e) => {
        // console.log('changed')
        const val = e.target.value
        let newVal;
        if (val === 'today') {
            //set types for today
            newVal = ratingLineChart(sessionsInfo.todayData)
            // console.log(newVal)
            setSessionsInfo(pre => ({ ...pre, ratings: newVal }))
            setTimeState(pre => ({...pre, line:"today"}))
        } else if (val === 'weekly') {
            //set tpyes for weekly
            newVal = ratingLineChart(sessionsInfo.weeklyData)
            setSessionsInfo(pre => ({ ...pre, ratings: newVal }))
            setTimeState(pre => ({...pre, line:"weekly"}))
        } else if (val === 'monthly') {
            //set types for monthly
            newVal = ratingLineChart(sessionsInfo.monthlyData)
            setSessionsInfo(pre => ({ ...pre, ratings: newVal }))
            setTimeState(pre => ({...pre, line:"monthly"}))
        } else if (val === 'allTime') {
            //set types for all time
            newVal = ratingLineChart(sessionsInfo.allTimeData)
            setSessionsInfo(pre => ({ ...pre, ratings: newVal }))
            setTimeState(pre => ({...pre, line:"allTime"}))
        }
        // console.log(val)
    }
    const handleFloBarChange = (e) => {
        const name = e.target.name
        const val = e.target.value
        if(name==="flowerMeth"){
            if(val === "time"){
              const timeSess = floTimeMethodBar(sessionsInfo.allTimeData)
              setSessionsInfo(pre => ({...pre, fm:timeSess}))
            } else {
                const ratingSess = flowerMethodBar(sessionsInfo.allTimeData)      
                setSessionsInfo(pre => ({...pre, fm:ratingSess}))
            }
            setTimeState(pre => ({...pre, rateTimeFlo:val}))
        }
    }
    const handleWaxBarChange = (e) => {
        const name = e.target.name
        const val = e.target.value
        if(name==="concentrateMeth"){
            if(val === "time"){
              const timeSess = waxTimeMethodBar(sessionsInfo.allTimeData)
              setSessionsInfo(pre => ({...pre, cm:timeSess}))
            } else {
                const ratingSess = concentrateMethodBar(sessionsInfo.allTimeData)      
                setSessionsInfo(pre => ({...pre, cm:ratingSess}))
            }
            setTimeState(pre => ({...pre, rateTimeWax:val}))
        }
    }
    const handleEdiBarChange = (e) => {
        const name = e.target.name
        const val = e.target.value
        if(name==="edibleMeth"){
            if(val === "time"){
              const timeSess = edibleTimeMethodBar(sessionsInfo.allTimeData)
              setSessionsInfo(pre => ({...pre, em:timeSess}))
            } else {
                const ratingSess = edibleMethodBar(sessionsInfo.allTimeData)      
                setSessionsInfo(pre => ({...pre, em:ratingSess}))
            }
            setTimeState(pre => ({...pre, rateTimeEdi:val}))
        }

    }
    // console.log('sess', sessionsInfo)
    return (
        <Screen align="is-align-items-center" justify="is-justify-content-center" nav={true} name="My Data">
            <div className="my-1 is-flex is-flex-wrap-wrap is-justify-content-space-evenly">
                <StatCard darkMode={darkMode} color="green" value={sessionsInfo.today} time="Today" />
                <StatCard darkMode={darkMode} color="blue" value={sessionsInfo.weekly} time="Weekly" />
                <StatCard darkMode={darkMode} color="pink" value={sessionsInfo.monthly} time="Monthly" />
                <StatCard darkMode={darkMode} color="purple" value={sessionsInfo.allTime} time="All Time" />
                <StatCard darkMode={darkMode} color="red" value={sessionsInfo.tolerance} time="Tolerance Break" />
            </div>
            <div className={`my-5 ${isMobile ? "vw-100" : "w-100"}`}>
                <TitleChart title="Cannabis Type" darkMode={darkMode} />
                <RadioGroup onChange={handlePieChange} radioVals="cal" darkMode={darkMode} name="pieRadio" currentVal={timeState.pie}/>
                <PieChart chartData={sessionsInfo.weedTypes} darkMode={darkMode} />
            </div>
            <div className={`my-5 ${isMobile ? "vw-100" : "w-100"}`}>
                <TitleChart title="Ratings" darkMode={darkMode} />
                <RadioGroup onChange={handleLineChange} radioVals="cal" darkMode={darkMode} name="ratingRadio" currentVal={timeState.line}/>
                <LineChart chartData={sessionsInfo.ratings} darkMode={darkMode} />
            </div>
            <div className={`my-5 ${isMobile ? "vw-100" : "w-100"}`}>
                <TitleChart title="Flower Methods" darkMode={darkMode} />
                <RadioGroup onChange={handleFloBarChange} radioVals="rateTime" darkMode={darkMode} name="flowerMeth" currentVal={timeState.rateTimeFlo}/>
                <BarChart chartData={sessionsInfo.fm} darkMode={darkMode} />
            </div>
            <div className={`my-5 ${isMobile ? "vw-100" : "w-100"}`}>
                <TitleChart title="Concentrate Methods" darkMode={darkMode} />
                <RadioGroup onChange={handleWaxBarChange} radioVals="rateTime" darkMode={darkMode} name="concentrateMeth" currentVal={timeState.rateTimeWax}/>
                <BarChart chartData={sessionsInfo.cm} darkMode={darkMode} />
            </div>
            <div className={`my-5 ${isMobile ? "vw-100" : "w-100"}`}>
                <TitleChart title="Edible Methods" darkMode={darkMode} />
                <RadioGroup onChange={handleEdiBarChange} radioVals="rateTime" darkMode={darkMode} name="edibleMeth" currentVal={timeState.rateTimeEdi}/>
                <BarChart chartData={sessionsInfo.em} darkMode={darkMode} />
            </div>
        </Screen>
    )
}

export default MyData