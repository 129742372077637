import React, { useContext } from "react"
import { ThemeContext } from '../context/ThemeContext'
import { Link } from "react-router-dom"
import { useMediaQuery } from 'react-responsive'

import Screen from "../components/Screen"
import logo from '../images/logo.svg'
import logoDark from '../images/logoDark.svg'
import chartSm from "../images/chartSm.png"
import cloud from "../images/cloudWeed.svg"
import cloudDark from "../images/cloudWeedDark.svg"

import Colors from "../theme/Colors"

const Landing = props => {
    // eslint-disable-next-line
    const [darkMode, setDarkMode] = useContext(ThemeContext);
    const isMobile = useMediaQuery({
        query: '(max-width: 870px)'
    })
    console.log(isMobile)
    const styles = {
        nav: {
            height: 70,
            width: "100vw",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 15,
            borderBottom: `1px solid ${Colors.lightGray}`
        },
        logoHolder: {
            display: 'flex',
            alignItems: "center",
        },
        logo: {
            height: 50,
        },
        logoName: {
            fontSize: isMobile ? 20 : 28,
            color: Colors.green,
            fontWeight: "700",
            marginLeft: 15,
        },
        icon: {
            color: darkMode ? Colors.blue : Colors.darkGray
        },
        contentHolder: {

        },
        textContainer: {
            maxWidth: 500,
            position: "relative",
        },
        title: {
            fontSize: 36,
            fontWeight: "800",
            color: darkMode ? Colors.stone : Colors.darkGray,
            zIndex:99,
        },
        titleContainer: {
            width: 350,
            zIndex:2,
        },
        contentContainer: {
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            height: "100%",
            width: "100%",
            flexGrow: 1,
            flexWrap: 'wrap',
        },
        chart: {
            height: 40,
            position: "absolute",
            right: isMobile ? 110 : 160,
            bottom: 12
        },
        subTextContainer: {
            display: "flex",
            flexDirection: "column",
            height: 250,
            justifyContent: "space-evenly"
        },
        subText: {
            fontSize: 18,
            fontWeight: "600",
            color: darkMode ? Colors.stone : Colors.darkGray,
            zIndex:2,
        },
        cloudContainer: {
            position: "relative",
            height: "100%",
            width: "50%",
            display: isMobile ? "none" : "flex"
        },
        cloudOne: {
            height: 150,
            position: "absolute",
            top: 95,
            left: 95,
        },
        cloudTwo: {
            height: 150,
            position: "absolute",
            top: '45%',
            right: 15
        },
        cloudThree: {
            height: 150,
            position: "absolute",
            bottom: 105,
            left: 75,
        },
        cloudOneMobile: {
            height: 95,
            position: "absolute",
            top: 85,
            left: 35,
            display: isMobile ? "block" : "none"
        },
        cloudTwoMobile: {
            height: 95,
            position: "absolute",
            top: '45%',
            right: 15,
            display: isMobile && !darkMode  ? "block" : "none",
        },
        cloudThreeMobile: {
            height: 95,
            position: "absolute",
            bottom: 15,
            left: 55,
            display: isMobile ? "block" : "none"
        },
    }


    return (
        <Screen align="is-align-items-center" fullHeight={true} justify="is-justify-content-flex" >
            <div style={styles.nav}>
                <div style={styles.logoHolder}>
                    <img src={darkMode ? logoDark : logo} alt="logo cloud" style={styles.logo} />
                    <p style={styles.logoName}>Higher Data</p>
                </div>
                <div style={styles.loginHolder}>
                    <span className="mr-4" onClick={() => setDarkMode(pre => !pre)} style={{ width: 50, height: 50, cursor: "pointer" }}><i style={styles.icon} className="pr-2 fas fa-moon fa-1x"></i></span>
                    <Link to="/login" style={{ color: Colors.green, fontSize: isMobile ? 16 : 18, fontWeight: "700", }}>Login</Link>
                </div>
            </div>
            <div style={styles.contentContainer}>
                <div style={styles.contentHolder}>
                    <div style={styles.textContainer}>
                        <span className="is-flex" style={styles.titleContainer}><h2 style={styles.title}>Achieve Higher With Data</h2><img src={chartSm} style={styles.chart} alt="chart" /></span>
                    </div>
                    <div style={styles.subTextContainer}>
                        <p style={styles.subText}>A fun way to keep track of your sessions.</p>
                        <p style={styles.subText}>Use past experiences so you never go overboard.</p>
                        <p style={styles.subText}>Perfect for first time users or seasoned tokers</p>
                    </div>
                    <img src={darkMode ? cloudDark : cloud} style={styles.cloudOneMobile} alt="weed cloud" />
                    <img src={darkMode ? cloudDark : cloud} style={styles.cloudTwoMobile} alt="weed cloud" />
                    <img src={darkMode ? cloudDark : cloud} style={styles.cloudThreeMobile} alt="weed cloud" />
                    <Link to={{ pathname: "/login", state: { fromRegister: true } }}> <button className="button my-4 is-medium" style={{ backgroundColor: Colors.blue, color: Colors.white, border: `2px solid ${Colors.blue}` }}>Register Now</button></Link>
                </div>
                <div style={styles.cloudContainer}>
                    <img src={darkMode ? cloudDark : cloud} style={styles.cloudOne} alt="weed cloud" />
                    <img src={darkMode ? cloudDark : cloud} style={styles.cloudTwo} alt="weed cloud" />
                    <img src={darkMode ? cloudDark : cloud} style={styles.cloudThree} alt="weed cloud" />
                </div>
            </div>
        </Screen>
    )
}

export default Landing