import { minutesToHours } from '../helpers/firebaseHelpers';
import Colors from '../theme/Colors';

export const todaySessionsDates = () => {
    // return an object start date and end date
    const start = new Date()
    start.setHours(0, 0, 0, 0);
    const end = new Date()
    end.setHours(23, 59, 59, 999)
    return {
        startAt: start.getTime(),
        endAt: end.getTime()
    }
}

export const weeklySessionsDates = () => {
    const date = new Date()
    const day = date.getDay()
    // const date = l.setDate(l.getDate() - 5 )
    let timeNum = null
    if (day > 0) {
        // means greater than sunday
        timeNum = date.setDate(date.getDate() - (day - 1))
    } else {
        // its sunday
        timeNum = date.setDate(date.getDate() - 6)
    }
    const monday = new Date(timeNum)
    const start = monday
    start.setHours(0, 0, 0, 0);
    const end = new Date()
    end.setHours(23, 59, 59, 999)
    return {
        startAt: start.getTime(),
        endAt: end.getTime()
    }
}

export const monthlySessionsDates = () => {
    const date = new Date()
    const month = new Date(date.getFullYear(), date.getMonth(), 1);
    const start = month
    start.setHours(0, 0, 0, 0);
    const end = new Date()
    end.setHours(23, 59, 59, 999)
    return {
        startAt: start.getTime(),
        endAt: end.getTime()
    }
}

export const allTimeSessions = created => {
    const start = created
    const end = new Date()
    end.setHours(23, 59, 59, 999)
    return {
        startAt: start,
        endAt: end.getTime()
    }
}

export const toleranceBreakDays = data => {
    // receive array of objects return a num of days since last partaking
    const date = new Date().getTime()
    const day = 86400000
    const lastSession = data[data.length - 1].date
    const difference = date - lastSession
    return Math.round(difference / day)
}

export const weedTypesDonut = data => {
    // return object with series and labels
    if (!data) return { labels: ['Flower', 'Concentrate', 'Edible'], series: [] }
    let flower = 0
    let concentrate = 0
    let edible = 0
    const total = data.length
    data.map(s => {
        if (s.weedTypeName === 'Flower') return flower++
        if (s.weedTypeName === 'Concentrate') return concentrate++
        if (s.weedTypeName === 'Edible') return edible++
        return s
    })
    const flowerPercent = Math.round((flower / total) * 100)
    const concentratePercent = Math.round((concentrate / total) * 100)
    const ediblePercent = Math.round((edible / total) * 100)
    // console.log(flowerPercent, concentratePercent, ediblePercent)

    const chartSeries = [flowerPercent, concentratePercent, ediblePercent]
    const chartLabels = ['Flower', 'Concentrate', 'Edible']
    return {
        labels: chartLabels,
        series: chartSeries
    }
}

/*
series: [{
    data: [{ x: '05/06/2014', y: 54 }, { x: '05/08/2014', y: 17 } , ... , { x: '05/28/2014', y: 26 }]
}]
series: [{
    type: 'line',
    data: [...]
  }, {
    type: 'column',
    data: [...]
  }]

*/


export const ratingLineChart = data => {
    // return an object with series array of x,y objects
    if (!data) return { labels: ['Flower', 'Concentrate', 'Edible'], series: [{ type: 'line', data: [] }] }
    const seriesInfo = data.map(o => {
        const date = new Date(o.date)
        o.displayDate = date.toLocaleDateString()
        o.x = o.displayDate
        o.y = o.ratingValue
        return o
    })
    const flowerSeries = seriesInfo.filter(o => o.weedTypeName === 'Flower')
    const concentrateSeries = seriesInfo.filter(o => o.weedTypeName === 'Concentrate')
    const ediblesSeries = seriesInfo.filter(o => o.weedTypeName === 'Edible')
    const lineData = orderByDate(flowerSeries, concentrateSeries, ediblesSeries)
    // console.log(lineData)
    return lineData
}

const orderByDate = (flower, wax, edible) => {
    const flowerDateArr = flower.sort((x, y) => y.date - x.date)
    const waxDateArr = wax.sort((x, y) => y.date - x.date)
    const edibleDateArr = edible.sort((x, y) => y.date - x.date)
    let flowerDate = flowerDateArr.length > 0 ? flowerDateArr[0] : null
    let waxDate = waxDateArr.length > 0 ? waxDateArr[0] : null
    let edibleDate = edibleDateArr.length > 0 ? edibleDateArr[0] : null

    // if any are null, we still need them to go through the IF check correctly. Setting to empty object then adding old date in the 60's
    if (!flowerDate) {
        flowerDate = {}
        flowerDate.date = new Date(15000254).getTime()
    }
    if (!waxDate) {
        waxDate = {}
        waxDate.date = new Date(15000254).getTime()
    }
    if (!edibleDate) {
        edibleDate = {}
        edibleDate.date = new Date(15000254).getTime()
    }

    if (flowerDate.date > waxDate.date && flowerDate.date > edibleDate.date) {
        // flower is the latest
        // console.log('flower hit')
        return {
            labels: ['Flower', 'Concentrate', 'Edible'],
            series: [
                {
                    type: 'line',
                    name: 'Flower',
                    data: flower.sort((x, y) => x.date - y.date)
                },
                {
                    type: 'line',
                    name: 'Concentrate',
                    data: wax.sort((x, y) => x.date - y.date)
                },
                {
                    type: 'line',
                    name: 'Edible',
                    data: edible.sort((x, y) => x.date - y.date)
                }
            ],
            colors: [Colors.green, Colors.blue, Colors.pink]
        }
    } else if (waxDate.date > flowerDate.date && waxDate.date > edibleDate.date) {
        // concentrate is the latest
        // console.log('wax hit')

        return {
            labels: ['Concentrate', 'Flower', 'Edible'],
            series: [
                {
                    type: 'line',
                    name: 'Concentrate',
                    data: wax.sort((x, y) => x.date - y.date)
                },
                {
                    type: 'line',
                    name: 'Flower',
                    data: flower.sort((x, y) => x.date - y.date)
                },
                {
                    type: 'line',
                    name: 'Edible',
                    data: edible.sort((x, y) => x.date - y.date)
                }
            ],
            colors: [Colors.blue, Colors.green, Colors.pink]
        }
    } else if (edibleDate.date > flowerDate.date && edibleDate.date > waxDate.date) {
        // edible is the latest
        // console.log('edible hit')

        return {
            labels: ['Edible', 'Flower', 'Concentrate'],
            series: [
                {
                    type: 'line',
                    name: 'Edible',
                    data: edible.sort((x, y) => x.date - y.date)
                },
                {
                    type: 'line',
                    name: 'Flower',
                    data: flower.sort((x, y) => x.date - y.date)
                },
                {
                    type: 'line',
                    name: 'Concentrate',
                    data: wax.sort((x, y) => x.date - y.date)
                }
            ],
            colors: [Colors.pink, Colors.green, Colors.blue]
        }

    }
    // something went wrong
    return null
}

const arrOfCOlors = (arr, colorChoice) => {
    //return an array of colors based on the color passed in
    // console.log(Colors[colorChoice])
    const color = Colors[colorChoice]
    return arr.map(o => color)
}
//this will need to add up all methods, getting a avg rating and time length

const ratingMethodBarHelper = (data, method, color) => {
    const weed = data.filter(f => f.weedTypeName === method)
    const labels = []
    const stats = []
    for (let i = 0; i < weed.length; i++) {
        //check if name exists if so add one
        //we need to have array og objects so when looping through weed we then do need to loop through stats to see if object name is in there
        const inStats = stats.filter(s => s.methodName === weed[i].methodName)
        // console.log('in stats', inStats)

        if (inStats.length > 0) {
            stats.map(s => {
                // add rating to the stat
                if (s.methodName === weed[i].methodName) {
                    // console.log('hit', s)
                    s.ratingTotal += weed[i].ratingValue
                    s.total += 1
                }
                return s
            })
        } else {
            // copy the first object and start an initial rating
            const stat = { ...weed[i] }
            stat.ratingTotal = weed[i].ratingValue
            stat.total = 1
            // console.log(stat)
            stats.push(stat)
        }
    }
    const avgArr = stats.map(s => {
        s.avg = Math.round(s.ratingTotal / s.total)
        return s
    })
    const sortArr = avgArr.sort((a, b) => a.methodName.localeCompare(b.methodName))
    const ratingsArr = sortArr.map(r => r.avg)
    sortArr.map(s => labels.push(s.methodName))
    const colors = arrOfCOlors(sortArr, color)
    // console.log(sortArr)
    return {
        key: "rate",
        labels: labels,
        colors: colors,
        series: [{ name: `${method} Methods`, data: ratingsArr }]
    }
}

const timeMethodBarHelper = (data, method, color) => {
    const weed = data.filter(f => f.weedTypeName === method)
    // console.log('we', weed)
    const labels = []
    const stats = []

    for (let i = 0; i < weed.length; i++) {
        const inStats = stats.filter(s => s.methodName === weed[i].methodName)
        // console.log('in stats', stats)

        if (inStats.length > 0) {
            stats.map(s => {
                // add rating to the stat
                if (s.methodName === weed[i].methodName) {
                    s.totalMin += weed[i].minutes
                    s.total += 1
                }
                return s
            })
        } else {
            const stat = { ...weed[i] }
            stat.totalMin = stat.minutes
            stat.total = 1
            stats.push(stat)
        }

    }
    // console.log('stats', stats)
    const avgArr = stats.map(s => {
        let percent = s.totalMin / s.total
        // console.log(s)
        s.avg = minutesToHours(percent)
        return s
    })
    const sortArr = avgArr.sort((a, b) => a.methodName.localeCompare(b.methodName))
    const methodsArr = sortArr.map(r => r.avg)
    sortArr.map(s => labels.push(s.methodName))
    const colors = arrOfCOlors(sortArr, color)
    // console.log('f you', sortArr)
    return {
        key: "time",
        labels: labels,
        colors: colors,
        series: [{ name: `${method} Methods`, data: methodsArr }]
    }
}

export const flowerMethodBar = (data) => {
    return ratingMethodBarHelper(data, "Flower", "green")
}

export const concentrateMethodBar = (data) => {
    return ratingMethodBarHelper(data, "Concentrate", "blue")
}

export const edibleMethodBar = (data) => {
    return ratingMethodBarHelper(data, "Edible", "pink")
}

export const floTimeMethodBar = (data) => {
    return timeMethodBarHelper(data, "Flower", "green")
}

export const waxTimeMethodBar = (data) => {
    return timeMethodBarHelper(data, "Concentrate", "blue")
}

export const edibleTimeMethodBar = (data) => {
    return timeMethodBarHelper(data, "Edible", "pink")
}

/*
rateMax: 10
rateMin: 1
show: true
timeMax: 12
timeMin: 0
types: "flower"
sort: rateAsc
*/

export const handleFiltering = (data, filters) => {
    // console.log(data, filters)
    // 1. ** Filter by the min & max first of time and rating // else if you need to filter by weed type too
    let dataArr = []
    if (filters.types === 'all') {
        dataArr = data.filter(s =>
            s.ratingValue >= filters.rateMin &&
            s.ratingValue <= filters.rateMax &&
            (s.minutes / 60) >= filters.timeMin &&
            (s.minutes / 60) <= filters.timeMax
        )
    } else {
        dataArr = data
            .filter(s =>
                s.ratingValue >= filters.rateMin &&
                s.ratingValue <= filters.rateMax &&
                (s.minutes / 60) >= filters.timeMin &&
                (s.minutes / 60) <= filters.timeMax &&
                s.weedTypeName.toLowerCase() === filters.types
            )
    }
    // 2. ** Then sort by the filter.sort and return that array. If nothing, just return the filtered arr
    switch (filters.sort) {
        case 'rateAsc':
                return dataArr.sort((x, y) => x.ratingValue - y.ratingValue)
        case 'rateDesc':
                return dataArr.sort((x, y) => y.ratingValue - x.ratingValue)
        case 'timeAsc':
                return dataArr.sort((x, y) => (x.minutes / 60) - (y.minutes / 60))
        case 'timeDesc':
                return dataArr.sort((x, y) => (y.minutes / 60) - (x.minutes / 60))
        default:
            return dataArr
    }

}