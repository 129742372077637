import dabRig from '../images/dabRig.svg'
import dabPen from '../images/dabPen.svg'

const DabSmokingMethods = [
    {
        id: 1,
        name: 'Dab Rig',
        img: dabRig,
        des: 'gets you really stoned',
        att: 'Icons made by <a href="https://www.flaticon.com/authors/smalllikeart" title="smalllikeart">smalllikeart</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>'
    },
    {
        id: 2,
        name: 'Dab Pen',
        img: dabPen,
        des: 'gets you really stoned',
    },
];

export default DabSmokingMethods;