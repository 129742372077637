const firebaseConfig = {
    apiKey: "AIzaSyBPPtasVMyrJiBxhZlU-5el-HdaZZjdPqM",
    authDomain: "higherdata-28bde.firebaseapp.com",
    databaseURL: "https://higherdata-28bde-default-rtdb.firebaseio.com",
    projectId: "higherdata-28bde",
    storageBucket: "higherdata-28bde.appspot.com",
    messagingSenderId: "345222927731",
    appId: "1:345222927731:web:ab04a0ca1314aa7bbce106",
    measurementId: "G-KJD6Z4VD88"
  };

  export default firebaseConfig;