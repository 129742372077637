import edible from '../images/edible.svg'
import wax from '../images/concentrate.svg'
import flower from '../images/flower.svg'

const WeedTypes = [
    {
        id: 1,
        name: 'Flower',
        img: flower,
        alt:'flower',
        des: 'gets you really stoned',
    },
    {
        id: 2,
        name: 'Concentrate',
        img: wax,
        alt:'wax concentrate',
        des: 'Be ready to blast off',
    },
    {
        id: 3,
        name: 'Edible',
        img: edible,
        alt:'cookie',
        des: 'Eat or drink to get medicated',
    },
    // {
    //     id: 4,
    //     name: 'Other',
    //     img: require("../../images/tincture.png"),
    //     des: 'Other Types of weed',
    // }
]

export default WeedTypes