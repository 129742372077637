import React, { createContext, useState } from 'react';

//Firebase Stuff
import firebase from "firebase/app";
import "firebase/analytics";
import firebaseConfig from '../firebase_config/firebaseConfig'
import "firebase/firebase-database"
import "firebase/firebase-auth"
firebase.initializeApp(firebaseConfig);

export const FirebaseContext = createContext();

export const FirebaseProvider = props => {
    const [Firebase] = useState(firebase);
    return (
        <FirebaseContext.Provider value={[Firebase]}>
            {props.children}
        </FirebaseContext.Provider>
    )
}