import React, { useState, useContext } from 'react'
import { FirebaseContext } from '../context/FirebaseContext'
import { ThemeContext } from '../context/ThemeContext'

import { Link, useHistory } from "react-router-dom";

import Screen from '../components/Screen'
import Colors from '../theme/Colors';


// import { confirmPassword, handleEmail, handlePassword } from '../helpers/signup';


const PasswordReset = props => {
    const [firebase] = useContext(FirebaseContext)
    // eslint-disable-next-line
    const [darkMode, setDarkMode] = useContext(ThemeContext);
    const [form, setForm] = useState({ email: '' })
    const [errorText, setErrorText] = useState('')
    const [resetComplete, setResetComplete] = useState(false)
    const history = useHistory()

    const handleOnChange = e => {
        setErrorText('')
        const value = e.target.value
        setForm(pre => ({ ...pre, email: value }))
    }
    const goBack = () => history.goBack()
    
    const handleSubmit = () => {
        firebase.auth().sendPasswordResetEmail(form.email).then(function (res) {
            // Email sent.
            setResetComplete(true)
        }).catch(err => {
            // An error happened.
            console.log(err)
            setErrorText('Unable to find email. Please try again.')
        });
    }
    const styles = {
        bgImg: {
            position: 'absolute',
            zIndex: -25,
        },
        text: {
            color: darkMode ? Colors.white : Colors.dark
        },
        input: {
            backgroundColor: darkMode ? Colors.dark : Colors.light,
            color: darkMode ? Colors.stone : Colors.darkGray,
            border:darkMode ? `1px solid ${Colors.stone}`  : null,
            height: 45,
            width:"100%",
            paddingLeft: 45,
        },
        backArrow: {
            position: "absolute",
            top: 25,
            left: 25,
        }
    }
    return (
        <Screen align="is-align-items-center" fullHeight={true} justify="is-justify-content-center">
            <span style={styles.backArrow} onClick={goBack}><i className="fas fa-arrow-left fa-2x" style={{color:darkMode ? Colors.white : Colors.darkGray}}></i></span>
            <p className="mb-4" style={styles.text}>Reset Your Password</p>
            <p className="mb-4 has-text-centered has-text-danger">{errorText}</p>
            {!resetComplete ? <div className="field">
                <p className="control has-icons-left has-icons-right">
                    <input style={styles.input} className={darkMode ? "inputDark": "input"} name="email" type="email" placeholder="Email" onChange={handleOnChange} value={form.email} />
                    <span className="icon is-small is-left">
                        <i className="fas fa-envelope"></i>
                    </span>
                    <span className="icon is-small is-right">
                        <i className="fas fa-check"></i>
                    </span>
                </p>
                <button className="button mt-5" style={{ width: '100%', backgroundColor:Colors.green, color:Colors.white }} onClick={handleSubmit}>Reset Password</button>
            </div>
                :
                <div>
                    <p className="mb-4 has-text-centered" style={styles.text}>A link to reset your password has been sent to your email</p>
                    <Link to="login"><button className="button is-primary mt-5" style={{ width: '100%' }}>Return To Login</button></Link>
                </div>
            }

        </Screen>
    )
}

export default PasswordReset