import React, {useContext} from "react"
import { ThemeContext } from '../context/ThemeContext'

import Colors from "../theme/Colors"

const Loading = () => {
        // eslint-disable-next-line
        const [darkMode, setDarkMode] = useContext(ThemeContext);

    const styles = {
        loader: {
            backgroundColor: darkMode ? Colors.dark : Colors.light,
        }
    }
    return (
        <div className="is-flex is-justify-content-center is-align-items-center" style={{width:"100%"}}>
            <progress className={`progress is-small is-primary ${darkMode ? 'dark' : null} px-2`} max="50" style={styles.loader}>15%</progress>
        </div>
    )
}

export default Loading