import React, { createContext, useState } from 'react';
  // eslint-disable-next-line
const PRODUCTION = 'Production'
  // eslint-disable-next-line
const TEST = 'Test'
export const EnvironmentContext = createContext();

export const EnvironmentProvider = props => {
    const [environment] = useState(PRODUCTION);
    return (
        <EnvironmentContext.Provider value={[environment]}>
            {props.children}
        </EnvironmentContext.Provider>
    )
}