import React, { useContext, useState } from 'react'
import { useHistory, Link } from "react-router-dom";
import { EnvironmentContext } from '../context/EnvironmentContext'
import { ThemeContext } from '../context/ThemeContext'
import { FirebaseContext } from '../context/FirebaseContext'
import { UserContext } from '../context/UserContext'

import Screen from '../components/Screen'
import Colors from '../theme/Colors'
import SupportForm from '../components/SupportForm';
import ListItem from '../components/ListItem';


const Settings = props => {
    const [environment] = useContext(EnvironmentContext)
    const [firebase] = useContext(FirebaseContext)
    // eslint-disable-next-line
    const [darkMode, setDarkMode] = useContext(ThemeContext);
    // eslint-disable-next-line
    const [user, setUser] = useContext(UserContext)
    const history = useHistory();
    const [showSupport, setShowSupport] =useState(false)
    const [supportForm, setSupportForm] = useState('')

    const logOut = () => {
        firebase.auth().signOut().then(() => {
            // Sign-out successful.
            history.push('/')
        }).catch((error) => {
            // An error happened.
            console.log(error)
        });
    }
    const handleSupportForm = (e) => setSupportForm(e.target.value)
    const submitForm = () => {
        const date = new Date()
        const id = date.getTime()
        const readDate = date.toLocaleDateString()
        firebase.database().ref(`${environment}/support/${id}/`).set({
            id:id,
            date: id,
            readDate:readDate,
            user:user.name,
            uid:user.uid,
            msg:supportForm 
        }).then(res => {
            if(res) console.log('err',  res)
            setSupportForm('')
        }).catch(err => console.log(err))
    }
    const styles = {
        infoHolder: {
            marginTop: 25,
            justifyContent: 'space-evenly',
            width:'100%',
            maxWidth:500
        },
        mainNumber: {
            fontSize: 100,
            textAlign: 'center',
            color: Colors.green
        },
        btnContainer: {
            width: '100%',
            bottom: 25,
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            padding: "0 15px"
            // bottom: 5
        },
        themeText: {
            color: darkMode ? Colors.white : Colors.dark
        },
        link: {
            display:'flex',
            justifyContent:'center',
        },
        version: {
            textAlign:"center",
            color: darkMode ? Colors.white : Colors.darkGray
        }
    }
    return (
        <Screen align="is-align-items-center" fullHeight={false} justify="is-justify-content-space-between" nav={true} name="Settings">
            <div style={styles.infoHolder} className="mb-5">
                <ListItem name="Support" show={showSupport} onClick={() => setShowSupport(pre => !pre)} darkMode={darkMode}/>
                <SupportForm show={showSupport} handleText={handleSupportForm} value={supportForm} darkMode={darkMode} handleSubmit={submitForm}/>
            </div>
            <div className="mt-6" style={styles.btnContainer}>
                <p style={styles.version}>version: 0.1.1 (beta)</p>
            <Link to="logsession" onClick={logOut} style={styles.link}><button className="button my-4 is-medium is-primary is-light" style={{ width: '100%', backgroundColor: Colors.green, color: Colors.white }}>Logout</button></Link>
            </div>
        </Screen>
    )
}

export default Settings