export const formatSessions = data => {
    return Object.values(data)
}

export const minutesToHours = mins => {
    if (mins < 60) {
        //return mintues formatted
        return mins * .01
    } else {
        const hour = (mins / 60).toFixed(2)
        return Number(hour)

    }
}