import React, { useContext,  } from 'react'
import { ThemeContext } from '../context/ThemeContext';
import { SessFiltersContext } from '../context/SessFiltersContext';
import { Link } from "react-router-dom"
import logo from '../images/logo.svg'
import logoDark from '../images/logoDark.svg'
import Menu from '../screens/Menu'
import Colors from '../theme/Colors';

const NavBar = props => {
    // eslint-disable-next-line
    const [darkMode, setDarkMode] = useContext(ThemeContext);
    const [filter, setFilters] = useContext(SessFiltersContext)
    const display = props.display
    const handleMenu = () => {
        props.menuClick()
    }
    const handleFilter = () => {
        const show = filter.show
        if (show){
            setFilters(pre => ({...pre, show: false }))
        } else {
            setFilters(pre => ({...pre, show: true }))
        }
    }
    const styles = {
        container: {
            position: 'sticky',
            top: 0,
            backgroundColor: darkMode ? Colors.dark : Colors.light,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: "0, 15px 0 15px",
            borderBottom: `2px solid ${Colors.lightGray}`,
            zIndex: 999,
            height: 55
        },
        title: {
            fontSize: 24,
            fontWeight: '700',
            color: darkMode ? Colors.white : Colors.darkGray,
            display: props.showMenu ? 'none' : 'block'
        },
        left: {
            width: 60,
        },
        right: {
            width: 60,
            color: darkMode ? Colors.white : Colors.darkGray,
            display: props.showMenu ? 'none' : 'flex',
            justifyContent: "center",
            alignItems: 'center',
        },
        logo: {
            height: 50,
            display: props.showMenu ? 'none' : 'block',
            padding: 4,
            marginLeft:8,
        },
        nav: {
            borderBottom: `1px solid ${Colors.lightGray}`
        },
        bars: {
            fontSize: 22,
        },
        filter: {
            position: 'absolute',
            right: 60,
            display: props.route === "MySessions" ? "block" : "none",
            color: filter.show ? Colors.green : darkMode ? Colors.white : Colors.darkGray
        }
    }
    if (!display) return <div></div>
    if(props.showMenu) return <Menu menuDisplay={handleMenu} title={props.title} />
    return (
        <div style={styles.container}>
            <div style={styles.left}>
                <Link to="/"><img src={darkMode ? logoDark : logo} style={styles.logo} alt="logo" /></Link>
            </div>
            <p style={styles.title}>{props.title}</p>
            <i style={styles.filter} className="fas fa-filter" onClick={handleFilter}></i>

            <div style={styles.right} >
                <i style={styles.bars} className="fas fa-bars" onClick={handleMenu}></i>
            </div>

            {/* {showMenu ? <Menu menuDisplay={handleMenu} title={props.title} /> : null} */}
        </div>
    )
}

export default NavBar


// return (
//     <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation" style={styles.constainer}>
//         <div className="navbar-brand is-flex is-align-items-center" style={styles.nav}>
//             <Link to="/"><img src={logo} style={{ width: 50, height: 50, marginLeft: 15 }} alt="logo" /></Link>
//             {/* <h2 className="pl-2 is-size-4 is-family-sans-serif">Higher Data</h2> */}
//             {showMenu ?
//                 <span style={styles.menuIcon} onClick={handleMenu}><i className="pr-2 fas fa-times fa-1.5x"></i></span>
//                       :
//                 <span style={styles.menuIcon} onClick={handleMenu}><i className="pr-2 fas fa-bars fa-1.5x"></i></span>
//             }   
//         </div>
//         {showMenu ? <Menu /> : null}
//     </nav>
// )