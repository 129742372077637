const Colors = {
    dark: '#121212',
    light: '#fff',
    white:'#fff',
    green:'#00D1B2',
    blue: '#00B2EE',
    pink:'#FF69B4',
    purple:'#4D37A1',
    red:'#F14668',
    lightGray: '#ccc',
    darkGray: '#888',
    stone:'#f8f8f8',
    transparent: 'transparent'
}

export default Colors