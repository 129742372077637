import React, { useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext'
import cloudWeed from '../images/cloudWeed.svg'
import cloudWeedDark from '../images/cloudWeedDark.svg'
import chartSm from "../images/chartSm.png"


import Colors from '../theme/Colors';

const NoData = props => {
    // eslint-disable-next-line
    const [darkMode, setDarkMode] = useContext(ThemeContext);

    const styles = {
        container: {
            height: 250,
            width:'100%',
            maxWidth: 655,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative'
        },
        cloud1: {
            height: 70,
            position: 'absolute',
            top: 5,
            left:5,
        },
        cloud2: {
            height: 70,
            position: 'absolute',
            top: 5,
            right:5,
        },
        cloud3: {
            height: 70,
            position: 'absolute',
            bottom: 0,
            left:  '50%',
            transform: 'translate(-50%, -10%)'
        },
        text: {
            color: darkMode ? Colors.white : Colors.lightGray,
            fontWeight: '700',
            fontSize: 36,
            marginRight: 10,

        },
        chart:{
            paddingBottom: 10,
            height: 55,
        },
        span: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            position: 'absolute',
            top: "50%",
            left:  '50%',
            transform: 'translate(-50%, -50%)'
        }
    }

    return (
        <div style={styles.container}>
            <img src={darkMode ? cloudWeedDark : cloudWeed} style={styles.cloud1} alt="logo" />
            <img src={darkMode ? cloudWeedDark : cloudWeed} style={styles.cloud2} alt="logo" />
            <img src={darkMode ? cloudWeedDark : cloudWeed} style={styles.cloud3} alt="logo" />
            <span style={styles.span}><p style={styles.text}>No Data</p><img src={chartSm} alt="chart" style={styles.chart}/></span>
        </div>
    )
}

export default NoData