const SessionTimes = [
    { id: 1, value: '15', minutes:15,name: "minutes", noImg: true, displayName: '15 minutes' },
    { id: 2, value: '30', minutes:30, name: "minutes", noImg: true, displayName: '30 minutes' },
    { id: 3, value: '45', minutes:45, name: "minutes", noImg: true, displayName: '45 minutes' },
    { id: 4, value: '1',  minutes:60,name: "hours", noImg: true, displayName: '1 hour' },
    { id: 5, value: '1:15', minutes:75, name: "hours", noImg: true, displayName: '1 hour and 15 minutes' },
    { id: 6, value: `1:30`, minutes:90, name: "hours", noImg: true, displayName: '1 hour and 30 minutes' },
    { id: 7, value: '1:45', minutes:105,name: "hours", noImg: true, displayName: '1 hour and 45 minutes' },
    { id: 8, value: '2:00', minutes:120, name: "hours", noImg: true, displayName: '2 hours' },
    { id: 9, value: '2:30', minutes:150, name: "hours", noImg: true, displayName: '2 hours and 30 minutes' },
    { id: 10, value: '3:00', minutes:180, name: "hours", noImg: true, displayName: '3 hours' },
    { id: 11, value: '3:30', minutes:210, name: "hours", noImg: true, displayName: '3 hours and 30 minutes' },
    { id: 12, value: '4:00', minutes:240, name: "hours", noImg: true, displayName: '4 hours' },
    { id: 13, value: '4:30', minutes:270, name: "hours", noImg: true, displayName: '4 hours and 30 minutes' },
    { id: 14, value: '5:00', minutes:300, name: "hours", noImg: true, displayName: '5 hours' },
    { id: 15, value: '5:30', minutes:330, name: "hours", noImg: true, displayName: '5 hours and 30 minutes' },
    { id: 16, value: '6:00', minutes:360, name: "hours", noImg: true, displayName: '6 hours' },
    { id: 17, value: '7:00', minutes:420,name: "hours", noImg: true, displayName: '7 hours' },
    { id: 18, value: '8:00', minutes:480,name: "hours", noImg: true, displayName: '8 hours' },
    { id: 19, value: '9:00', minutes:540,name: "hours", noImg: true, displayName: '9 hours' },
    { id: 20, value: '10:00', minutes:600,name: "hours", noImg: true, displayName: '10 hours' },
    { id: 21, value: '11:00', minutes:660, name: "hours", noImg: true, displayName: '11 hours' },
    { id: 22, value: '12:00', minutes:720,name: "hours", noImg: true, displayName: '12 hours' }





];

export default SessionTimes