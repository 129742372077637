import React from 'react'
import { Link } from "react-router-dom";

import Colors from '../theme/Colors'

const LoginForm = props => {
    const isRegister = props.register 
    const darkMode = props.darkMode
    const styles = {
        helperText: {
            color: darkMode ? Colors.white : Colors.darkGray,
            cursor: "pointer"
        },
        input: {
            backgroundColor: darkMode ? Colors.dark : Colors.light,
            color: darkMode ? Colors.stone : Colors.darkGray,
            border:darkMode ? `1px solid ${Colors.stone}`  : null,
            height: 45,
            width:"100%",
            paddingLeft: 45,
            fontSize:17,
        },
        greenText: {
            color: Colors.green
        }
    }
    return (
        <div style={{maxWidth:400, minWidth:300}}>
            {isRegister ? <div className="field">
                <p className="control has-icons-left has-icons-right">
                    <input 
                        className={darkMode ? "inputDark" : "input"}
                        style={styles.input}
                        name="name" 
                        type="text" 
                        placeholder="First Name" 
                        onChange={props.onChange} 
                        value={props.value.name}
                    />
                    <span className="icon is-small is-left">
                        <i className="fas fa-user-alt"></i>
                    </span>
                    <span className="icon is-small is-right">
                        <i className="fas fa-check"></i>
                    </span>
                </p>
            </div> : null}
            <div className="field">
                <p className="control has-icons-left has-icons-right">
                    <input 
                        className={darkMode ? "inputDark" : "input"}
                        style={styles.input}
                        name="email" type="email" 
                        placeholder="Email" 
                        onChange={props.onChange} 
                        value={props.value.email}
                    />
                    <span className="icon is-small is-left">
                        <i className="fas fa-envelope"></i>
                    </span>
                    <span className="icon is-small is-right">
                        <i className="fas fa-check"></i>
                    </span>
                </p>
            </div>
            <div className="field">
                <p className="control has-icons-left">
                    <input 
                        className={darkMode ? "inputDark" : "input"}
                        style={styles.input}
                        name="password" 
                        type="password" 
                        placeholder="Password" 
                        onChange={props.onChange} 
                        value={props.value.password}
                    />
                    <span className="icon is-small is-left">
                        <i className="fas fa-lock"></i>
                    </span>
                </p>
            </div>
            {isRegister ? <div className="field">
                <p className="control has-icons-left">
                    <input 
                        className={darkMode ? "inputDark" : "input"}
                        style={styles.input}
                        name="confirm-password" 
                        type="password" 
                        placeholder="Confirm Password" 
                        onChange={props.onChange} 
                        value={props.value.confirmPw}
                    />
                    <span className="icon is-small is-left">
                        <i className="fas fa-lock"></i>
                    </span>
                </p>
            </div> : null}
            <div className="field">
                <p className="control">
                    <button className="button" style={{width: '100%', backgroundColor:Colors.green, border:`2px solid ${Colors.green}`, color:Colors.white}} onClick={props.onSubmit}>
                        {isRegister ? 'Register' :'Login'}
                    </button>
                </p>
            </div>
            {isRegister ?
             <p className="has-text-centered" style={styles.helperText}>Already have an account? <span style={styles.greenText} onClick={props.switchForm}>Login here</span></p> 
            :
            <div> 
            <p className="has-text-centered" style={styles.helperText}>Don't Have An Account? You can <span style={styles.greenText} onClick={props.switchForm}>register here</span></p>
            <p style={styles.helperText} className="mt-4 has-text-centered">Forgot Your Password?</p>
            <Link to="passwordreset"><p style={{...styles.helperText, ...{textAlign:'center', fontSize:11, color:Colors.green}}} className="mt-2 has-text-centered">Click Here to Reset</p></Link>
        </div>
        }
        </div>
    )
}

export default LoginForm