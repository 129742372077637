import React from 'react'
import Colors from '../theme/Colors';
import Loading from './Loading';

const StatCard = ({ darkMode, value, time, color }) => {

    const styles = {
        card: {
            width: 130,
            height: 130,
            // backgroundColor: Colors.blue,
            color: darkMode ? Colors.white : Colors.dark,
            border: `2px solid ${Colors[color]}`,
            borderRadius: 15,
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            margin:10
        },
        statNum: {
            color: Colors[color],
            fontSize: 30,
            fontWeight:'600'
        },
        header: {
            fontSize: 14,
            fontWeight: '600',
        }
    }
    return (
        <div style={styles.card}>
            <p style={styles.header}>{time}</p>
            {!value && value !==0 ? <Loading /> :
            <p style={styles.statNum}>{value}</p>}
            <p>{time === 'Tolerance Break' ? 'Days' : 'Sessions'}</p>
        </div>
    )
}

export default StatCard;