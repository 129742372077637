import bakedGoods from '../images/bakedGoods.svg'
import candy from '../images/candy.svg'
import chocolate from '../images/chocolate.svg'
import drinks from '../images/drinks.svg'
import gummy from '../images/gummy.svg'
import tincture from '../images/tincture.svg'
const EdibleMethods = [
    {
        id: 1,
        name: 'Baked Goods',
        img: bakedGoods,
        des: 'gets you really stoned',
        att: 'Icons made by <a href="https://www.flaticon.com/authors/smalllikeart" title="smalllikeart">smalllikeart</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>'
    },
    {
        id: 2,
        name: 'Candy',
        img: candy,
        des: 'gets you really stoned',
        att: 'Icons made by <a href="https://www.flaticon.com/authors/smalllikeart" title="smalllikeart">smalllikeart</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>'
    },
    {
        id: 3,
        name: 'Chocolate',
        img: chocolate,
        des: 'gets you really stoned',
        att: 'Icons made by <a href="https://www.flaticon.com/authors/smalllikeart" title="smalllikeart">smalllikeart</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>'
    },
    {
        id: 4,
        name: 'Drinks',
        img: drinks,
        des: 'gets you really stoned',
        att: 'Icons made by <a href="https://www.flaticon.com/authors/smalllikeart" title="smalllikeart">smalllikeart</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>'
    },
    {
        id: 5,
        name: 'Gummy',
        img: gummy,
        des: 'gets you really stoned',
        att: 'Icons made by <a href="https://www.flaticon.com/authors/smalllikeart" title="smalllikeart">smalllikeart</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>'
    },
    {
        id: 6,
        name: 'Tincture',
        img: tincture,
        des: 'gets you really stoned',
        att: 'Icons made by <a href="https://www.flaticon.com/authors/smalllikeart" title="smalllikeart">smalllikeart</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>'
    },
];

export default EdibleMethods;