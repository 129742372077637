export const handleEmail = (email) => {
    const validEmail = /^[^\s@]+@[^\s@]+$/
    const valid = validEmail.test(email);
    if (!valid) return { valid: false, msg: 'Email is not valid. Please try again.' }
    return { valid: true }
}

export const handlePassword = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    if (password.length < 6) return { valid: false, msg: 'Password must be greater than 6 characters' }
    if (!hasLowerCase) return { valid: false, msg: 'Password must have at least one lowercase letter' }
    if (!hasUpperCase) return { valid: false, msg: 'Password must have at least one uppercase letter' }
    if (!hasNumbers) return { valid: false, msg: 'Password must have at least one number' }
    return { valid: true }
}

export const confirmPassword = (password, confirmPw) => {
    if(password.length !== confirmPw.length) return { valid: false, msg: 'Passwords do not match' }
    for(let i=0; i<password.length; i++){
        if(password[i] !== confirmPw[i]) return { valid: false, msg: 'Passwords do not match' }
    }
    return { valid: true }
}