import './App.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom"
import PrivateRoute from './components/PrivateRoute'
import { EnvironmentProvider } from './context/EnvironmentContext'
import { ThemeProvider } from './context/ThemeContext'
import { FirebaseProvider } from './context/FirebaseContext'
import { UserProvider } from './context/UserContext'
import { SessFiltersProvider } from './context/SessFiltersContext'


//Screens
import Login from './screens/Login'
import Home from './screens/Home'
import MyData from './screens/MyData'
import LogSession from './screens/LogSession'
import MySessions from './screens/MySessions'
import Settings from './screens/Settings'
import PasswordReset from './screens/PasswordReset'
import Landing from './screens/Landing'
import ResetPW from './screens/ResetPW'


// firebase.analytics();

function App() {
  return (
    <EnvironmentProvider>
    <FirebaseProvider>
      <ThemeProvider>
        <UserProvider>
          <SessFiltersProvider>
        <div className="App">
          <div className="">
            <Router>
              <Switch>
                <Route path="/resetyourpassword">
                  <ResetPW />
                </Route>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/welcome">
                  <Landing />
                </Route>
                <Route path="/passwordreset">
                  <PasswordReset />
                </Route>
                <PrivateRoute path="/" exact>
                  <Home />
                </PrivateRoute>
                <PrivateRoute path="/logsession">
                  <LogSession />
                </PrivateRoute>
                <PrivateRoute path="/mydata">
                  <MyData />
                </PrivateRoute>
                <PrivateRoute path="/mysessions">
                  <MySessions />
                </PrivateRoute>
                <PrivateRoute path="/settings">
                  <Settings />
                </PrivateRoute>
              </Switch>
            </Router>
          </div>
        </div>
        </SessFiltersProvider>
        </UserProvider>
      </ThemeProvider>
    </FirebaseProvider>
    </EnvironmentProvider>
  );
}

export default App;
