import React, { useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext'

import Colors from '../theme/Colors';

const NotesInput = props => {
    const [darkMode] = useContext(ThemeContext);

    const styles = {
        inputWrapper:{
            width: '100%'
        },
        input: {
            backgroundColor: darkMode ? Colors.dark : Colors.light,
            border: darkMode ? `2px solid ${Colors.light}` : null,
            color: darkMode ? Colors.white : Colors.dark,
            width: '100%',
            padding: 10,
            fontSize: 18,
            borderRadius: 10
        }
    }

    return (
        <div style={styles.inputWrapper}>
            <textarea 
                value={props.value} 
                onChange={props.onChange} 
                style={styles.input} 
                rows="10" 
                placeholder="log notes here...">
            </textarea>
        </div>
    )
}

export default NotesInput