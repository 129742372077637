const Ratings = [
    {id:1, text:'[ 1 ]', name:"one", value:1, noImg:true},
    {id:2, text:'[ 2 ]', name:"two", value:2, noImg:true},
    {id:3, text:'[ 3 ]', name:"three",value:3, noImg:true}, 
    {id:4, text:'[ 4 ]', name:"four", value:4, noImg:true}, 
    {id:5, text:'[ 5 ]', name:"five", value:5, noImg:true}, 
    {id:6, text:'[ 6 ]', name:'six', value:6, noImg:true}, 
    {id:7, text:'[ 7 ]', name:'seven', value:7, noImg:true}, 
    {id:8, text:'[ 8 ]', name:'eight', value:8, noImg:true}, 
    {id:9, text:'[ 9 ]', name:'nine', value:9, noImg:true}, 
    {id:10,text:'[ 10 ]', name:'ten', value:10, noImg:true}
];

export default Ratings;