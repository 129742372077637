import React, { createContext, useEffect, useState } from 'react';
import Colors from '../theme/Colors'

export const ThemeContext = createContext();

export const ThemeProvider = props => {
    const [darkMode, setDarkMode] = useState(false);
    useEffect(() => {
        const time = new Date().getHours()
            // eslint-disable-next-line
        if(time > 18 && time <= 23 || time <=6) {
            setDarkMode(true)
            document.body.style.backgroundColor = Colors.dark
        }
    }, [])
    return (
        <ThemeContext.Provider value={[darkMode, setDarkMode]}>
            {props.children}
        </ThemeContext.Provider>
    )
}