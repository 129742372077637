import React, { useContext, useEffect, useState } from 'react'
import { EnvironmentContext } from '../context/EnvironmentContext'
import { ThemeContext } from '../context/ThemeContext'
import { FirebaseContext } from '../context/FirebaseContext'
import { UserContext } from '../context/UserContext'

import { Link } from "react-router-dom"
import Screen from '../components/Screen'
import Colors from '../theme/Colors'


const Home = props => {
    const [environment] = useContext(EnvironmentContext)
    const [firebase] = useContext(FirebaseContext)
    // eslint-disable-next-line
    const [darkMode, setDarkMode] = useContext(ThemeContext);
    // eslint-disable-next-line
    const [user, setUser] = useContext(UserContext)

    const [session, setSession] = useState({})

    useEffect(() => {
     firebase.database().ref(`${environment}/users/${user.uid}/sessions`).orderByChild("date").limitToLast(1).once('value', snap =>{
        const sess = snap.val()
        if(!sess) return
        const sesArr = Object.values(sess)
        setSession(sesArr[0])
        const newUser = false
        localStorage.setItem("newUser", newUser)
        }).catch(err => console.log(err))
            // eslint-disable-next-line
    },[])
    const styles = {
        infoHolder: {
            marginTop: 25,
            justifyContent: 'space-evenly'
        },
        mainNumber: {
            fontSize: 100,
            textAlign: 'center',
            color: Colors.green
        },
        btnContainer: {
            // position: 'fixed',
            width: '100%',
            bottom: 25,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        themeText: {
            color: darkMode ? Colors.white : Colors.dark
        }
    }
    return (
        <Screen align="is-align-items-center" fullHeight={false} justify="is-justify-content-space-between" nav={true} name="Higher Data" route="Home">
            <div style={styles.infoHolder} className="mb-5">
                <p className="has-text-left is-size-3" style={styles.themeText}>Hi {user.name}!</p>
                <p style={styles.mainNumber}>{session.ratingValue}</p>
                <p className="is-size-6" style={styles.themeText}>Your last session lasted {session.timeDisplayName}</p>
            </div>
            <div className="mt-6" style={styles.btnContainer}>
                <Link to="logsession"><button className="button my-4 is-medium" style={{ width: '100%', backgroundColor: Colors.green, color: Colors.white }}>Log Session</button></Link>
                <Link to="/mydata"> <button className="button is-primary my-4 is-medium" style={{ width: '100%', backgroundColor: Colors.pink, color: Colors.white }}>My Data</button></Link>
                <Link to="/mysessions"><button className="button is-danger my-4 is-medium" style={{ width: '100%', backgroundColor: Colors.blue, color: Colors.white }}>My Sessions</button></Link>

            </div>
        </Screen>
    )
}

export default Home