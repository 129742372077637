import React, { useState, useContext, useEffect } from 'react'
import { EnvironmentContext } from '../context/EnvironmentContext'
import { FirebaseContext } from '../context/FirebaseContext'
import { UserContext } from '../context/UserContext'
import { ThemeContext } from '../context/ThemeContext'
import { useHistory, Link } from "react-router-dom";
import Screen from '../components/Screen'
import LoginForm from '../components/LoginForm'
import Logo from '../images/logo.svg'
import LogoDark from '../images/logoDark.svg'

import { confirmPassword, handleEmail, handlePassword } from '../helpers/signup';


const Login = props => {
    const [environment] = useContext(EnvironmentContext)
    const [firebase] = useContext(FirebaseContext)
    // eslint-disable-next-line
    const [user, setUser] = useContext(UserContext)
    // eslint-disable-next-line
    const [darkMode, setDarkMode] = useContext(ThemeContext);
    const history = useHistory()
    const [register, setRegister] = useState(false)
    const [form, setForm] = useState({ name: '', email: '', password: '', confirmedPw: '' })
    const [errorText, setErrorText] = useState('')

    useEffect(() => {
        if (history.location.state && history.location.state.fromRegister) setRegister(true)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            if (user) return history.push("/")
        })
        // eslint-disable-next-line
    }, [])

    const handleForm = (e) => {
        setErrorText('')
        const name = e.target.name
        const value = e.target.value
        if (name === 'name') setForm(pre => ({ ...pre, name: value }))
        if (name === 'email') setForm(pre => ({ ...pre, email: value }))
        if (name === 'password') setForm(pre => ({ ...pre, password: value }))
        if (name === 'confirm-password') setForm(pre => ({ ...pre, confirmedPw: value }))
    }

    const switchForm = () => setRegister(val => !val)

    const handleSubmit = () => {
        const isEmailValid = handleEmail(form.email)
        if (!isEmailValid.valid) return setErrorText(isEmailValid.msg)

        if (register) {
            const pwValid = handlePassword(form.password)
            if (!pwValid.valid) return setErrorText(pwValid.msg)
            const passwordMatch = confirmPassword(form.password, form.confirmedPw)
            if (!passwordMatch.valid) return setErrorText(passwordMatch.msg)
            // register the user
            firebase.auth().createUserWithEmailAndPassword(form.email, form.password).then(user => {
                const profile = {
                    uid: user.user.uid,
                    name: form.name,
                    email: user.user.email,
                    createdAt: new Date().getTime()
                }
                firebase.database().ref(`${environment}/users/${user.user.uid}/profile`).set(profile).then(err => {
                    setUser(profile)
                    history.push('/')
                }).catch(err => console.log(err))

            }).catch(err => {
                console.log(err)
            })
        } else {
            // log the user in
            firebase.auth().signInWithEmailAndPassword(form.email, form.password).then(user => {
                firebase.database().ref(`${environment}/users/${user.user.uid}/profile`).once('value', (snapshot) => {
                    const userProfile = snapshot.val()
                    setUser(userProfile)
                })

                history.push('/')
            }).catch(err => {
                console.log(err)
                setErrorText(err.message)
            })
        }
    }

    return (
        <Screen align="is-align-items-center" fullHeight={true} justify="is-justify-content-center">
            <Link to="/welcome">
                <img src={darkMode ? LogoDark : Logo} height={150} width={150} className="my-4" alt="logo" />
            </Link>
            <p className="my-2 has-text-centered has-text-danger">{errorText}</p>
            <LoginForm register={register} switchForm={switchForm} onChange={handleForm} value={form} onSubmit={handleSubmit} darkMode={darkMode} />

        </Screen>
    )
}

export default Login