import React from "react"
import { methodArrRadio, timeFunctionsArr, filterTypeArrRadio } from "../helpers/general"
import Colors from "../theme/Colors"
import RadioButtonWrap from "./RadioButtonWrap"

const RadioGroup = ({ onChange, darkMode, name, currentVal, radioVals }) => {
    let groupOpts = [];
    if(radioVals === "cal"){
        // today, monthly etc
         groupOpts = timeFunctionsArr
    } else if (radioVals === "rateTime"){
        //by rating, by time
        groupOpts = methodArrRadio
    } else if (radioVals === "filterTypes"){
        groupOpts = filterTypeArrRadio
    }
    // console.log(currentVal)
    // console.log(groupOpts)
    const styles = {
        container: {
            justifyContent: 'space-evenly',
            width: '100%'
        },
        label: {
            color: darkMode ? Colors.white : Colors.dark
        }
    }

        return (
        <div className="is-flex my-3" style={styles.container}>
            {groupOpts.map(o => (
            <label className="radio mx-2" style={styles.label} id={o.id}key={o.id}>
                <RadioButtonWrap checked={currentVal} darkMode={darkMode}>
                <input
                    type="radio"
                    id={o.id}
                    key={o.id}
                    value={o.value}
                    name={radioVals === "cal" ? name : name}
                    className="mr-1"
                    onChange={onChange}
                    style={{opacity:0, zIndex:2}}
                />
                </RadioButtonWrap>
                {o.display}
            </label>))}
            
        </div>
    )
}

export default RadioGroup