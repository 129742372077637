import React from "react";
import Colors from "../theme/Colors";

const MinMax = ({ darkMode, min, max, handleMinUp, handleMinDown, handleMaxUp, handleMaxDown, asc, desc, handleSort, sortVal }) => {

    const styles = {
        container: {
            // backgroundColor: Colors.dark,
            display: 'flex',
            justifyContent: 'space-evenly'
        },
        input: {
            height: 50,
            border: '1px solid lightgray',
            backgroundColor: Colors.transparent
        },
        num: {
            fontSize: 42,
            color: Colors.green,
            marginRight: 10,
        },
        icon: {
            color: darkMode ? Colors.stone : Colors.lightGray
        },
        lable: {
            position: 'absolute',
            top: 0,
            left: 5,
            color: darkMode ? Colors.stone : Colors.darkGray
        },
        asc: {
            color: sortVal === asc ? Colors.green : darkMode ? Colors.stone : Colors.darkGray,
            fontWeight: 700,
        },
        desc: {
            color: sortVal === desc ? Colors.green : darkMode ? Colors.stone : Colors.darkGray,
            fontWeight: 700,
        }
    }
    return (
        <div style={styles.container}>
            <div className="is-flex is-align-items-center" style={{ position: 'relative', height: 90 }}>
                <p style={styles.lable}>Min</p>
                <div style={styles.num}>{min}</div>
                <div className="is-flex is-flex-direction-column">
                    <i style={{ ...styles.icon, ...styles.minUp }}
                        className="pr-2 fas fa-chevron-up fa-2x"
                        onClick={handleMinUp}
                    >
                    </i>
                    <i style={{ ...styles.icon, ...styles.minDown }} className="pr-2 fas fa-chevron-down fa-2x" onClick={handleMinDown}></i>
                </div>
            </div>
            <div className="is-flex is-align-items-center" style={{ position: 'relative', height: 90 }}>
                <p style={styles.lable}>Max</p>
                <div style={styles.num}>{max}</div>
                <div className="is-flex is-flex-direction-column">
                    <i style={{ ...styles.icon, ...styles.maxUp }} className="pr-2 fas fa-chevron-up fa-2x" onClick={handleMaxUp}></i>
                    <i style={{ ...styles.icon, ...styles.maxDown }} className="pr-2 fas fa-chevron-down fa-2x" onClick={handleMaxDown}></i>
                </div>
            </div>
            <div className="is-flex is-flex-direction-column is-align-items-center" style={{ position: 'relative', height: 80 }}>
                <p style={{color: darkMode ? Colors.stone : Colors.darkGray}}>Sort</p>
                <div className="is-flex">
                    <p className="mt-4 mr-3" style={styles.asc} name={asc} value={asc} onClick={e => handleSort(e,asc)}>ASC</p>
                    <p className="mt-4 ml-3" style={styles.desc} name={desc} value={desc} onClick={e => handleSort(e,desc)}>DESC</p>
                </div>
            </div>
        </div>
    )
}

export default MinMax